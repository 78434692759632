import { useEffect, useState } from "react";
import QRScan from "../../components/qr_scan";
import { Controller, useForm } from "react-hook-form";
import CONFIG from "../../constanta/config";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import useUser from "../../hook/useUser";
import ReactSelect from "react-select";
import moment from "moment";
import { useQuery } from "../../hook/useQuery";
import helper from "../../utils/helper";

const FormRFIDResult = (props) => {
  const [loading, setLoading] = useState(false);
  const query = useQuery();
  const [checkAllow, setCheckAllow] = useState("");
  const [showGuest, setShowGuest] = useState(false);
  const [listGuest, setListGuest] = useState([]);
  const [gate, setGate] = useState(null);

  useEffect(() => {
    if (props.user.event_gate) {
      let _gate = props.user.event_gate?.find(
        (v) => v.id_gate === query.get("id_gate")
      );
      setGate(_gate);
    }
  }, [props?.user]);

  useEffect(() => {
    initAllowed();
  }, [gate]);

  useEffect(() => {
    if (checkAllow !== "") {
      if (checkAllow === "allowed-to-checkin") {
        setTimeout(() => {
          setLoading(true);

          handleCheckin();
        }, 10);
        return;
      } else if (checkAllow === "allowed-to-re-checkin") {
        setTimeout(() => {
          setLoading(true);

          handleReCheckin();
        }, 10);
        return;
      }
    }
  }, [checkAllow]);

  const togleGuest = (idx) => {
    let arr = [...listGuest];
    arr[idx].active = !arr[idx].active;
    setListGuest(arr);
  };

  const onSubmit = () => {
    if (loading) return;

    setLoading(true);

    handleCheckin();
  };

  const getPointCheckin = () => {
    if (query.get("id_gate") !== "1") {
      return props?.setting?.point_main_entrance_checkin;
    } else {
      return props?.setting?.point_pillar_checkin;
    }
  };

  const handleReCheckin = () => {
    const payload = {
      id_user: props?.user?.id_user,
      id_gate: query.get("id_gate"),
    };

    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    try {
      axios
        .post(CONFIG.URL + "/event/reentry", form_data, {
          headers: {
            token: Cookies.get("token"),
          },
        })

        .then(function (response) {
          setLoading(false);
          if (response?.data.status === "SUCCESS") {
            props.onSuccess();
          } else {
            if (response?.data?.error_code === "not_checkout") {
              props.onSuccess();
            }
          }
        })
        .catch(function (error) {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  };

  const handleCheckin = () => {
    const payload = {
      id_user: props?.user?.id_user,
      id_gate: query.get("id_gate"),
      point_user: getPointCheckin(),
    };

    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    try {
      axios
        .post(CONFIG.URL + "/event/checkin", form_data, {
          headers: {
            token: Cookies.get("token"),
          },
        })

        .then(function (response) {
          setLoading(false);
          if (response?.data.status === "SUCCESS") {
            props.onSuccess();
          } else {
          }
        })
        .catch(function (error) {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  };

  const handleReentry = () => {
    const payload = {
      id_user: props?.user?.id_user,
      id_gate: query.get("id_gate"),
    };

    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    try {
      axios
        .post(CONFIG.URL + "/event/reentry", form_data, {
          headers: {
            token: Cookies.get("token"),
          },
        })

        .then(function (response) {
          setLoading(false);
          if (response?.data.status === "SUCCESS") {
            props.onSuccess();
          } else {
          }
        })
        .catch(function (error) {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  };

  const initAllowed = () => {
    if (gate !== null) {
      if (gate?.checkin_time !== "0000-00-00 00:00:00") {
        setCheckAllow("allowed-to-re-checkin");
      } else {
        setCheckAllow("allowed-to-checkin");
      }
    }
  };

  const getCheckinTime = () => {
    if (Date.parse(gate?.reentry_time) > Date.parse(gate?.checkin_time)) {
      return gate?.reentry_time;
    } else {
      return gate?.checkin_time;
    }
  };

  const handleShowGuest = () => {
    if (showGuest) {
      setListGuest([]);
      setShowGuest(false);
    } else {
      setListGuest([
        {
          label: "Guest 1",
          active: true,
        },
        {
          label: "Guest 2",
          active: false,
        },
        {
          label: "Guest 3",
          active: false,
        },
      ]);
      setShowGuest(true);
    }
  };

  return (
    <>
      <div className="space-y-5 px-5 py-10 bg-white rounded-md w-full md:w-1/2 transition-all">
        <div className="bg-green-500 w-full text-center py-5 text-white font-bold rounded">
          Loading...
        </div>
      </div>
    </>
  );

  // return (
  //   <>
  //     <div className="space-y-3 px-5 py-3 bg-white rounded-md items-center justify-center flex flex-col w-full md:w-1/2 transition-all">
  //       <div className="text-center font-bold">
  //         <div className="">CHECKIN</div>
  //         <div className="text-xl uppercase">{query.get("gate")}</div>
  //         <div className="text-xl uppercase mb-1">{helper.getPilarName(query.get("id_gate"))}</div>
  //       </div>

  //       {(checkAllow === "allowed-to-checkin" || checkAllow === "allowed-to-re-checkin") && (
  //         <div className="bg-green-500 w-full text-center py-2 px-1 text-white text-sm font-bold rounded">
  //           ALLOWED TO ENTER
  //         </div>
  //       )}

  //       {/* {checkAllow === "allowed-to-re-checkin" ||
  //         (checkAllow === "allowed-to-re-checkin-new" && (
  //           <div className="bg-green-500 w-full text-center py-2 px-1 text-white text-sm font-bold rounded">
  //             ALLOWED TO RE-ENTRY
  //           </div>
  //         ))} */}

  //       {checkAllow === "already-checkin" && (
  //         <div className="bg-red-500 w-full text-center py-2 px-1 text-white text-sm font-bold rounded">
  //           ALREADY CHECKIN : {getCheckinTime()}
  //         </div>
  //       )}

  //       {checkAllow === "not-allowed-to-checkin" && (
  //         <div className="bg-red-500 w-full text-center py-2 px-1 text-white text-sm font-bold rounded">
  //           NOT ALLOWED TO ENTER
  //         </div>
  //       )}

  //       {checkAllow === "wrong-gate" && (
  //         <div className="bg-red-500 w-full text-center py-2 px-1 text-white text-sm font-bold rounded">
  //           WRONG GATE
  //         </div>
  //       )}

  //       {/* <div className="bg-red-500 w-full text-center py-5 text-white font-bold rounded">
  //         ALREADY CHECKED IN
  //       </div> */}

  //       <div
  //         className={`cursor-pointer text-white flex flex-col justify-center items-center  text-black font-bold px-5 py-1 rounded`}
  //       >
  //         {props.user !== null ? (
  //           <>
  //             {props.user?.rfid !== "" && (
  //               <>
  //                 {/* <div className="mb-2 flex flex-col justify-center items-center  text-black font-bold px-5 py-1 rounded">
  //                   {checkAllow === "allowed-to-re-checkin" && (
  //                     <img
  //                       src={CONFIG.URL_MEDIA + "/" + gate?.photo}
  //                       className="rounded w-full h-full  bject-contain"
  //                       alt=""
  //                     />
  //                   )}

  //                   {checkAllow === "allowed-to-re-checkin-new" && (
  //                     <img
  //                       src={CONFIG.URL_MEDIA + "/" + props.user?.photo}
  //                       className="rounded w-full h-full  bject-contain"
  //                       alt=""
  //                     />
  //                   )}
  //                 </div> */}
  //                 <div className="text-center text-xl font-bold text-black uppercase">
  //                   {props.user.name}
  //                 </div>
  //                 <div className="text-center text-xl font-bold text-black uppercase mt-2 mb-4">
  //                   <span className="text-sm">TEAM :</span>
  //                   <br />
  //                   {props.user.team}
  //                 </div>
  //               </>
  //             )}
  //           </>
  //         ) : (
  //           <>
  //             {" "}
  //             <div className="text-center  font-bold text-black text-4xl mt-4">
  //               NOT FOUND
  //             </div>
  //           </>
  //         )}
  //       </div>

  //       <>
  //         <div className="mx-auto flex justify-center items-center space-x-4">
  //           <button
  //             type="button"
  //             onClick={() => props.ok()}
  //             className={`px-10 py-2 rounded-lg text-white font-bold bg-red-500  w-full text-center`}
  //           >
  //             CANCEL
  //           </button>

  //           {/* {(checkAllow === "allowed-to-re-checkin" ||
  //             checkAllow === "allowed-to-re-checkin-new" ||
  //             checkAllow === "allowed-to-checkin") && (
  //             <button
  //               type="button"
  //               onClick={() => onSubmit()}
  //               className={`px-10 py-2 rounded-lg text-white font-bold bg-green-500  w-full text-center`}
  //             >
  //               {loading ? "Loading..." : "CONFIRM"}
  //             </button>
  //           )} */}
  //         </div>
  //       </>
  //     </div>
  //   </>
  // );
};

export default FormRFIDResult;

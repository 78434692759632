import { useEffect, useState } from "react";
import CONFIG from "../../constanta/config";
import axios from "axios";
import Cookies from "js-cookie";
import FormVerification from "./verification.from";
import FormThankyou from "./thankyou.from";
import FormNameResult from "./name-result";
import FormRFIDResult from "./rfid-result";
import FormThankyouRFID from "./thankyou-rfid.from";
import helper from "../../utils/helper";
import FormQRResult from "./qr-result";

const PageRedeemPoint = (props) => {
  const [options, setOptions] = useState([]);
  const [user, setUser] = useState(null);
  const [state, setState] = useState("verification");
  const [qrCode, setQrCode] = useState("");

  useEffect(() => {
    if (Cookies.get("token") === undefined) {
      window.location.href = "./home";
    }
  }, []);

  useEffect(() => {
    // let a = [{ label: "dicky", value: "0943898324", id_user: "1" }];
    // setOptions(a);
    // getLitsUser();
  }, []);

  const getLitsUser = async () => {
    const response = await axios.get(
      CONFIG.URL + "/user?limit=1000000&filter=vipgate",
      {
        headers: {
          token: Cookies.get("token"),
        },
      }
    );

    if (response?.data?.status === "SUCCESS") {
      // let list = response.data?.data?.filter((item) => item.is_register_at_event !== "1");

      setOptions(
        response.data?.data.map((item) => {
          return {
            ...item,
            label: item.username,
            value: item.id_user,
          };
        })
      );

      // let a = response.data?.data.filter((v) => v.qr_code === null);
      // //console.log(a);
    }
  };

  const handleSuccesverification = (data, type, qr = false) => {
    setUser(data);

    if (type === "name") {
      setState("name-result");
    } else if (type === "rfid") {
      setState("rfid-result");
      setQrCode(qr);
    } else {
      setState("qr-result");
      setQrCode(qr);
    }
  };

  const handleResetForm = () => {
    setState("verification");
  };

  const handleSuccessCheckinName = () => {
    setState("thankyou");
  };

  const handleSuccessCheckinRFID = () => {
    setState("thankyou-rfid");
  };

  return (
    <>
      {
        <div
          className="w-full min-h-screen flex flex-col justify-start items-center bg-gray-200 px-5 bg-cover bg-no-repeat"
          style={{
            backgroundPosition: "center",
            backgroundImage: `url('${helper.getBackground(
              props?.setting?.background
            )}')`,
          }}
        >
          <div className="flex flex-col justify-center items-center py-10 w-full">
            <div className="flex flex-col justify-center items-center space-y-2.5 w-full">
              {/* VERIFICATION */}
              {state === "verification" && (
                <>
                  <FormVerification
                    qrCode={qrCode}
                    onSuccess={(data, type, qrCode = false) =>
                      handleSuccesverification(data, type, qrCode)
                    }
                    options={options}
                  />
                </>
              )}

              {/* NAME RESULT */}
              {state === "name-result" && (
                <FormNameResult
                  user={user}
                  ok={() => handleResetForm()}
                  onSuccess={() => handleSuccessCheckinName()}
                />
              )}

              {/* NAME RESULT */}
              {state === "rfid-result" && (
                <FormRFIDResult
                  setting={props?.setting}
                  user={user}
                  ok={() => handleResetForm()}
                  onSuccess={() => handleSuccessCheckinRFID()}
                />
              )}

              {/* NAME RESULT */}
              {state === "qr-result" && (
                <FormQRResult
                  qrCode={qrCode}
                  user={user}
                  ok={() => handleResetForm()}
                  onSuccess={() => handleSuccessCheckinName()}
                />
              )}

              {/* THANK YOU NAME RESULT */}
              {state === "thankyou" && (
                <FormThankyou user={user} ok={() => handleResetForm()} />
              )}

              {/* THANK YOU RFID RESULT */}
              {state === "thankyou-rfid" && (
                <FormThankyouRFID ok={() => handleResetForm()} />
              )}
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default PageRedeemPoint;

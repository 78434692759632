import CONFIG from "../constanta/config";

function getBackground(url) {
  return url;

  if(url !== undefined && url !== null && url !== ""){
    let arr = url.split("/");
    return CONFIG.URL_MEDIA + "/" + arr[arr.length - 1];
  }
  else{
    return "";
  }
}

function getPilarName(gate) {
  switch(gate){
    case "2":
      return "Digital Experience";

    case "3":
      return "SME";

    case "4":
      return "Efficiency";

    case "5":
      return "Republic Life";

    case "6":
      return "Trust and Wealth Management";

    case "7":
      return "Mergers and Acquisitions";

    default:
      return "";
  }
}

export default {
  getBackground,
  getPilarName
};

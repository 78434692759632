import { useEffect, useState } from "react";
import QRScan from "../../components/qr_scan";
import { Controller, useForm } from "react-hook-form";
import CONFIG from "../../constanta/config";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import useUser from "../../hook/useUser";
import ReactSelect from "react-select";
import moment from "moment";
import { useQuery } from "../../hook/useQuery";
import PopupConfirmRedeem from "./popup-confirm-redeem";

const FormRFIDResult = (props) => {
  const [loading, setLoading] = useState(false);
  const query = useQuery();
  const [checkAllow, setCheckAllow] = useState("");
  const [showGuest, setShowGuest] = useState(false);
  const [listGuest, setListGuest] = useState([]);
  const [gate, setGate] = useState(null);
  const [pointUser, setPointUser] = useState(0);
  const [pointTeam, setPointTeam] = useState(0);
  const [msg, setMsg] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [usedPoint, setUsedPoint] = useState(0);
  const [listRedeem, setListRedeem] = useState([]);

  useEffect(() => {
    if (props.user.event_gate) {
      let _gate = props.user.event_gate?.find((v) => v.id_gate === query.get("id_gate"));
      setGate(_gate);
    }
  }, [props?.user]);

  useEffect(() => {
    initAllowed();
    getList();
  }, [gate]);

  
  const getList = async () => {

    setListRedeem(JSON.parse(props?.setting?.list_redeem_item));
  }



  // const listRedeem = [
  //   {
  //     name: 'item 1',
  //     point: 100
  //   },
  //   {
  //     name: 'item 2',
  //     point: 50
  //   },
  //   {
  //     name: 'item 3',
  //     point: 300
  //   },
  //   {
  //     name: 'item 4',
  //     point: 250
  //   }
  // ]

  const initAllowed = () => {
    if (gate !== null) {
      if (gate?.checkin_time !== "0000-00-00 00:00:00") {
        setCheckAllow("allowed-to-add");
      } else {
        if(gate.checkout_time !== "0000-00-00 00:00:00") {
          if(moment(gate.reentry_time) > moment(gate.checkout_time)){
            setCheckAllow("allowed-to-add");
          }
          else{
            setCheckAllow("not-allowed-to-add");
          }
        }
        else{
          setCheckAllow("not-allowed-to-add");
        }
      }
    }
  };

  const calcPointBalance = () => {
    let p = parseInt(props.user?.point_user) - usedPoint;
    return p;
  }

  const calcUsedPoint = (val) => {
    setUsedPoint(usedPoint + val);
    setSelectedItem(null)
  }

  

  return (
    <>
      {selectedItem !== null && (
        <PopupConfirmRedeem
          calcUsedPoint={(val) => calcUsedPoint(val)}
          user={props?.user}
          item={selectedItem}
          onClose={() => setSelectedItem(null)}
        />
      )}
      <div className="space-y-3 px-5 py-3 bg-white rounded-md items-center justify-center flex flex-col w-full md:w-1/2 transition-all">
        <div className="text-center font-bold">
          <div className="text-black font-semibold text-xl">Redeem</div>
          <div className="text-2xl uppercase">{query.get("gate")}</div>
        </div>

        <hr className="border w-full h-[1px]" />

        {checkAllow === "allowed-to-add" && (
          <div>
            <div className="text-center text-xl font-bold text-black uppercase">
              {props.user.name}
            </div>
            <div className="text-center text-xl font-bold text-black uppercase mt-2 mb-2">
              <span className="text-sm">TEAM :</span>
              <br />
              {props.user.team}
            </div>

            <div className="text-black text-center font-semibold text-xl mb-2">
              Points Balance:{" "}
              <span className="font-bold">{calcPointBalance()}</span>
            </div>

            <hr className="border w-full h-[1px]" />

            <div>
              <div className="flex flex-col space-y-3 my-4 items-center justify-center">
                <div className="text-black font-semibold text-xl">
                  Select Items to Redeem
                </div>

                <div className="grid grid-cols-2 gap-4 w-full">
                  {listRedeem?.map((item, key) => {
                    return (
                      <button
                        key={key}
                        disabled={calcPointBalance() < item.point}
                        onClick={() => setSelectedItem(item)}
                        className={`${calcPointBalance() < item.point ? "bg-gray-500" : "bg-blue-500"}  w-full rounded-md px-4 py-2 text-white font-bold  flex flex-col items-center`}
                      >
                        <div className="uppercase">{item.name}</div>
                        <div className="text-2xl">{item.point}</div>
                      </button>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        )}

        {checkAllow === "not-allowed-to-add" && (
          <div className="w-full">
            <div className="bg-red-500 w-full text-center py-2 px-2 text-white font-bold rounded">
              Haven't Checked in
            </div>

            <div className="text-center text-xl font-bold text-black uppercase mt-4">
              {props.user.name}
            </div>
            <div className="text-center text-xl font-bold text-black uppercase mt-2 mb-4">
              <span className="text-sm">TEAM :</span>
              <br />
              {props.user.team}
            </div>
          </div>
        )}

        <div className="text-center font-bold text-red-500">{msg}</div>

        <>
          <div className="mx-auto flex justify-center items-center space-x-4">
            <button
              type="button"
              onClick={() => props.ok()}
              className={`px-10 py-2 rounded-lg text-white font-bold bg-red-500  w-full text-center`}
            >
              CANCEL
            </button>

            {/* {checkAllow === "allowed-to-add" && (
              <div className="flex space-x-5">
                <button
                  type="button"
                  onClick={() => onSubmit()}
                  className={` min-w-[160px] px-10 py-2 rounded-lg text-white font-bold bg-green-500  w-full text-center`}
                >
                  {loading ? "LOADING..." : "CONFIRM"}
                </button>
              </div>
            )} */}
          </div>
        </>
      </div>
    </>
  );
};

export default FormRFIDResult;

import { useCallback, useEffect, useRef, useState } from "react";
import QRScan from "../../components/qr_scan";
import { Controller, useForm } from "react-hook-form";
import CONFIG from "../../constanta/config";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import useUser from "../../hook/useUser";
import ReactSelect from "react-select";
import moment from "moment";
import { useQuery } from "../../hook/useQuery";
import iconCheck from "../../assets/check.png";

import Webcam from "react-webcam";
import helper from "../../utils/helper";

const FormRFIDResult = (props) => {
  const webcamRef = useRef(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [loading, setLoading] = useState(false);
  const query = useQuery();
  const [checkAllow, setCheckAllow] = useState("");
  const [showGuest, setShowGuest] = useState(false);
  const [listGuest, setListGuest] = useState([]);
  const [gate, setGate] = useState(null);

  // Sample binary data representing a small transparent PNG image (1x1 pixel)
  const imageData = new Uint8Array([
    137,
    80,
    78,
    71, // PNG signature
    13,
    10,
    26,
    10, // \r\n\x1A\n
    0,
    0,
    0,
    13, // IHDR chunk length
    73,
    72,
    68,
    82, // IHDR
    0,
    0,
    0,
    1, // Width (1 pixel)
    0,
    0,
    0,
    1, // Height (1 pixel)
    8, // Bit depth
    2, // Color type (truecolor with alpha)
    0, // Compression method (deflate/inflate)
    0, // Filter method
    0, // Interlace method
    4,
    242,
    73,
    68,
    65,
    84,
    8,
    29,
    1,
    1,
    0,
    0,
    0,
    215,
    88,
    195,
    192,
    0,
    0,
    0,
    13,
    73,
    68,
    65,
    84,
    8,
    29,
    97,
    1,
    0,
    0,
    0,
    0,
    10,
    245,
    97,
    24,
    1,
    0,
    0,
    0,
    0,
    0,
    0,
    141,
    56,
    66,
    172,
    0,
    0,
    0,
    0,
    73,
    69,
    78,
    68,
    174,
    66,
    96,
    130,
  ]);

  // Create a Blob object from the image data
  const blobData = new Blob([imageData], { type: "image/png" });

  // Create a new File object
  const newImageFile = new File([blobData], "photo.png", {
    type: "image/png",
    lastModified: Date.now(),
  });

  useEffect(() => {
    if (props.user.event_gate) {
      let _gate = props.user.event_gate?.find(
        (v) => v.id_gate === query.get("id_gate")
      );
      setGate(_gate);
    }
  }, [props?.user]);

  useEffect(() => {
    if (checkAllow !== "") {
      if (
        checkAllow === "allowed-to-checkout" &&
        query.get("id_gate") !== "1"
      ) {
        setTimeout(() => {
          setLoading(true);

          handleCheckout();
        }, 10);
        return;
      }
    }
  }, [checkAllow]);

  useEffect(() => {
    initAllowed();
  }, [gate]);

  useEffect(() => {
    if (checkAllow !== "") {
      if (
        checkAllow === "already-checkout" ||
        checkAllow === "havent-checkin"
      ) {
        setTimeout(() => {
          props.ok()
        }, 1500);
        return;
      }
    }
  }, [checkAllow]);

  const togleGuest = (idx) => {
    let arr = [...listGuest];
    arr[idx].active = !arr[idx].active;
    setListGuest(arr);
  };

  const onSubmit = () => {
    if (loading) return;

    setLoading(true);

    handleCheckout();
  };

  const videoConstraints = {
    width: { min: 720 },
    height: { min: 480 },
    facingMode: "user",
  };

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const capture = useCallback(() => {
    setImageSrc(webcamRef.current.getScreenshot());
  }, [webcamRef]);

  const retake = () => {
    setImageSrc(null);
  };

  const canSubmit = () => {
    if ((query.get("id_gate") === "1") & (imageSrc === null)) {
      return false;
    } else {
      return true;
    }
  };

  const handleCheckout = () => {
    if (query.get("id_gate") === "1" && imageSrc === null) {
      return;
    }

    var file =
      query.get("id_gate") === "1"
        ? dataURLtoFile(imageSrc, "photo.png")
        : newImageFile;

    const payload = {
      id_user: props?.user?.id_user,
      id_gate: query.get("id_gate"),
      photo: file,
    };

    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    try {
      axios
        .post(CONFIG.URL + "/event/checkout", form_data, {
          headers: {
            token: Cookies.get("token"),
          },
        })

        .then(function (response) {
          setLoading(false);
          if (response?.data.status === "SUCCESS") {
            props.onSuccess();
          } else {
          }
        })
        .catch(function (error) {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  };

  const handleReentry = () => {
    const payload = {
      id_gate: query.get("id_gate"),
      rfid: props?.user?.rfid,
    };

    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    try {
      axios
        .post(CONFIG.URL + "/event/rfidx/reentry", form_data, {
          headers: {
            token: Cookies.get("token"),
          },
        })

        .then(function (response) {
          setLoading(false);
          if (response?.data.status === "SUCCESS") {
            props.onSuccess();
          } else {
          }
        })
        .catch(function (error) {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  };

  const initAllowed = () => {
    // if (props.user.exit_time !== undefined) {
    //   setCheckAllow("allowed-to-re-checkin-new");
    // } else {
    // }

    if (gate !== null) {
      if (gate.checkout_time === "0000-00-00 00:00:00") {
        if (gate.checkin_time !== "0000-00-00 00:00:00") {
          setCheckAllow("allowed-to-checkout");
        } else {
          setCheckAllow("havent-checkin");
        }
      } else {
        if (
          moment(gate.checkin_time) < moment(gate.checkout_time) &&
          gate.reentry_time === "0000-00-00 00:00:00"
        ) {
          setCheckAllow("already-checkout");
        } else if (moment(gate.reentry_time) < moment(gate.checkout_time)) {
          setCheckAllow("already-checkout");
        } else {
          setCheckAllow("allowed-to-checkout");
        }
      }
    } else {
      setCheckAllow("wrong-gate");
    }
  };

  const getCheckinTime = () => {
    if (Date.parse(gate?.reentry_time) > Date.parse(gate?.checkin_time)) {
      return gate?.reentry_time;
    } else {
      return gate?.checkin_time;
    }
  };

  const handleShowGuest = () => {
    if (showGuest) {
      setListGuest([]);
      setShowGuest(false);
    } else {
      setListGuest([
        {
          label: "Guest 1",
          active: true,
        },
        {
          label: "Guest 2",
          active: false,
        },
        {
          label: "Guest 3",
          active: false,
        },
      ]);
      setShowGuest(true);
    }
  };

  if (query.get("id_gate") !== "1" && checkAllow === "allowed-to-checkout") {
    return <></>;
  }

  return (
    <>
      <div className="space-y-3 px-5 py-3 bg-white rounded-md items-center justify-center flex flex-col w-full md:w-1/2 transition-all">
        <div className="text-center font-bold">
          <div>CHECKOUT</div>
          <div className="text-xl uppercase">{query.get("gate")}</div>
          <div className="text-xl uppercase mb-1">
            {helper.getPilarName(query.get("id_gate"))}
          </div>
        </div>

        {checkAllow === "allowed-to-checkout" && (
          <div className="uppercase bg-green-500 w-full text-center py-2 text-white font-bold rounded">
            ALLOWED TO Checkout
          </div>
        )}

        {checkAllow === "already-checkout" && (
          <div className="bg-red-500 w-full text-center py-2 text-white font-bold rounded">
            ALREADY CHECKOUT
          </div>
        )}

        {checkAllow === "havent-checkin" && (
          <div className="bg-red-500 w-full text-center py-2 text-white font-bold rounded">
            Haven't Checked in
          </div>
        )}

        {checkAllow === "wrong-gate" && (
          <div className="bg-red-500 w-full text-center py-2 text-white font-bold rounded">
            WRONG GATE
          </div>
        )}

        {/* <div className="bg-red-500 w-full text-center py-5 text-white font-bold rounded">
          ALREADY CHECKED IN
        </div> */}

        <div
          className={`cursor-pointer text-white flex flex-col justify-center items-center  text-black font-bold px-5 py-1 rounded`}
        >
          {props.user !== null ? (
            <>
              {!props?.user?.notfound && (
                <div>
                  {" "}
                  <div className="text-center text-xl font-bold text-black uppercase">
                    {props.user.name}
                  </div>
                  <div className="text-center text-xl font-bold text-black uppercase mt-2 mb-4">
                    <span className="text-sm">TEAM :</span>
                    <br />
                    {props.user.team}
                  </div>
                </div>
              )}

              {checkAllow === "allowed-to-checkout" &&
                query.get("id_gate") === "1" && (
                  <>
                    <div className="relative shadow-lg drop-shadow-lg overflow-hidden max-w-[320px] mt-5">
                      <div
                        className={`${
                          imageSrc !== null ? "invisible" : "visible"
                        }`}
                      >
                        <Webcam
                          audio={false}
                          height={600}
                          ref={webcamRef}
                          screenshotFormat="image/jpeg"
                          width={800}
                          videoConstraints={videoConstraints}
                          screenshotQuality={1}
                          imageSmoothing={true}
                          mirrored
                        />
                      </div>

                      {imageSrc !== null && (
                        <img
                          alt=""
                          src={imageSrc}
                          className={`${
                            imageSrc !== null ? "block" : "hidden"
                          } w-full h-full object-contain absolute top-0 left-0`}
                        />
                      )}
                    </div>

                    <div className="flex justify-center items-center my-2">
                      {imageSrc === null ? (
                        <button
                          onClick={() => capture()}
                          type="button"
                          className={`cursor-pointer w-max px-4 py-1 rounded-lg text-white font-bold bg-blue-500  text-center`}
                        >
                          Capture
                        </button>
                      ) : (
                        <button
                          onClick={() => retake()}
                          type="button"
                          className={`cursor-pointer w-max px-4 py-1 rounded-lg text-white font-bold bg-orange-500  text-center`}
                        >
                          Retake
                        </button>
                      )}
                    </div>
                  </>
                )}
            </>
          ) : (
            <>
              {" "}
              <div className="text-center  font-bold text-black text-4xl mt-4">
                NOT FOUND
              </div>
            </>
          )}
        </div>

        <>
          <div className="mx-auto flex justify-center items-center space-x-4">
            {checkAllow === "allowed-to-checkout" && (
              <button
                type="button"
                onClick={() => props.ok()}
                className={`px-10 py-2 rounded-lg text-white font-bold bg-red-500  w-full text-center`}
              >
                CANCEL
              </button>
            )}

            {checkAllow === "allowed-to-checkout" &&
              query.get("id_gate") === "1" && (
                <div className="flex space-x-5">
                  {canSubmit() ? (
                    <button
                      type="button"
                      onClick={() => onSubmit()}
                      className={` min-w-[160px] px-10 py-2 rounded-lg text-white font-bold bg-green-500  w-full text-center`}
                    >
                      {loading ? "LOADING..." : "CONFIRM"}
                    </button>
                  ) : (
                    <button
                      className={`cursor-not-allowed min-w-[160px] px-10 py-2 rounded-lg text-white font-bold bg-gray-500  w-full text-center`}
                    >
                      Confirm
                    </button>
                  )}
                </div>
              )}
          </div>
        </>
      </div>
    </>
  );
};

export default FormRFIDResult;

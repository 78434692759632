import { useEffect, useState } from "react";
import QRScan from "../../components/qr_scan";
import { Controller, useForm } from "react-hook-form";
import CONFIG from "../../constanta/config";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import useUser from "../../hook/useUser";
import ReactSelect from "react-select";
import moment from "moment";
import { useQuery } from "../../hook/useQuery";
import { useRecordWebcam } from "react-record-webcam";
import RecordVideo from "./record";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile } from "@ffmpeg/util";
import helper from "../../utils/helper";
import imgLogo from "../../assets/logo-rbl.png";

const FormRFIDResult = (props) => {
  const [loading, setLoading] = useState(false);
  const query = useQuery();
  const [checkAllow, setCheckAllow] = useState("");
  const [showGuest, setShowGuest] = useState(false);
  const [listGuest, setListGuest] = useState([]);
  const [gate, setGate] = useState(null);
  const [feedbackType, setFeedbackType] = useState("text");
  const [msg, setMsg] = useState("");
  const [feel, setFeel] = useState("");
  const [impact, setImpact] = useState("");

  const [videoRecord, setVideoRecord] = useState(null);

  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setMsg("");
  }, [feedbackType]);

  useEffect(() => {
    if (props.user.event_gate) {
      let _gate = props.user.event_gate?.find(
        (v) => v.id_gate === query.get("id_gate")
      );
      setGate(_gate);
    }
  }, [props?.user]);

  useEffect(() => {
    initAllowed();
  }, [gate]);

  // useEffect(() => {
  //   if (checkAllow !== "") {
  //     if (checkAllow === "allowed-to-checkin") {
  //       setTimeout(() => {
  //         handleCheckin();
  //       }, 1000);
  //       return;
  //     } else {
  //       if (checkAllow !== "allowed-to-re-checkin") {
  //         setTimeout(() => {
  //           window.location.reload();
  //         }, 1500);
  //         return;
  //       }
  //     }
  //   }
  // }, [checkAllow]);

  const togleGuest = (idx) => {
    let arr = [...listGuest];
    arr[idx].active = !arr[idx].active;
    setListGuest(arr);
  };

  const convertToMp4 = async (webmBlob) => {
    debugger;
    const ffmpeg = new FFmpeg({ log: true });
    await ffmpeg.load();

    await ffmpeg.writeFile("input.webm", await fetchFile(webmBlob));

    await ffmpeg.exec(["-i", "input.webm", "output.mp4"]);

    const fileData = await ffmpeg.readFile("output.mp4");
    const data = new Uint8Array(fileData);
    return new Blob([data.buffer], { type: "video/mp4" });
  };

  const onSubmit = (data) => {
    if (loading) return;

    // if(feedbackType === "text"){
    //   if(data?.feedback?.length < parseInt(props?.setting?.maximum_feedback_text)){
    //     setMsg(`Your feedback must be ${props?.setting?.maximum_feedback_text} or more characters`);
    //     return;
    //   }

    // }

    if(feel === ""){
      setMsg("Please answer question number 1");
      return;
    }

    if(impact === ""){
      setMsg("Please answer question number 2");
      return;
    }

    setLoading(true);

    handleAddFeedback(data);
  };

  const handleAddFeedback = async (data) => {
    setMsg("");
    const payload = {
      email: props?.user?.email,
      id_gate: query.get("id_gate"),
      point_user: props?.setting?.point_bonus,
      feedback_type: feedbackType,
    };

    if (feedbackType === "text") {
      payload.feedback = `${feel} | ${impact} | ${data?.feedback}`;
    }

    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    if (feedbackType === "video" && videoRecord !== null) {
      form_data.append(
        "video",
        videoRecord.blob,
        props?.user?.email + "_video.webm"
      );
    }

    try {
      axios
        .post(CONFIG.URL + "/event/point/feedback", form_data)

        .then(function (response) {
          setLoading(false);
          if (response?.data.status === "SUCCESS") {
            props.onSuccess();
          } else {
            setMsg("Error: " + response.data?.message);
          }
        })
        .catch(function (error) {
          setMsg("Error: Something went wrong please try again");
          setLoading(false);
        });
    } catch (error) {
      setMsg("Error: Something went wrong please try again");
      setLoading(false);
    }
  };

  const initAllowed = () => {
    // if (gate !== null) {
    //   if (gate?.checkin_time !== "0000-00-00 00:00:00") {
    //     setCheckAllow("allowed-to-add");
    //   } else {
    //     if (gate.checkout_time !== "0000-00-00 00:00:00") {
    //       if (moment(gate.reentry_time) > moment(gate.checkout_time)) {
    //         setCheckAllow("allowed-to-add");
    //       } else {
    //         setCheckAllow("not-allowed-to-add");
    //       }
    //     } else {
    //       setCheckAllow("not-allowed-to-add");
    //     }
    //   }
    // }

    setCheckAllow("allowed-to-add");
  };

  const getCheckinTime = () => {
    if (Date.parse(gate?.reentry_time) > Date.parse(gate?.checkin_time)) {
      return gate?.reentry_time;
    } else {
      return gate?.checkin_time;
    }
  };

  const handleShowGuest = () => {
    if (showGuest) {
      setListGuest([]);
      setShowGuest(false);
    } else {
      setListGuest([
        {
          label: "Guest 1",
          active: true,
        },
        {
          label: "Guest 2",
          active: false,
        },
        {
          label: "Guest 3",
          active: false,
        },
      ]);
      setShowGuest(true);
    }
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="space-y-3 px-5 py-3 bg-white rounded-md items-center justify-center flex flex-col w-full md:w-1/2 transition-all"
      >
        <div>
          <img className="w-20 h-20 rounded-md" src={imgLogo} />
        </div>

        <div className="text-center font-bold">
          <div className="text-2xl uppercase">{query.get("gate")}</div>
          <div className="text-xl uppercase mb-1">
            {helper.getPilarName(query.get("id_gate"))}
          </div>
          <div className="text-black font-semibold text-xl">
            Please share your feedback with us:
          </div>
        </div>

        <hr className="border w-full h-[1px]" />

        {checkAllow === "allowed-to-add" && (
          <div className="w-full">
            <div className="w-full">
              <div className="text-center text-xl font-bold text-black uppercase">
                {props.user.name}
              </div>
              <div className="text-center text-xl font-bold text-black uppercase mt-2 mb-4">
                <span className="text-sm">TEAM :</span>
                <br />
                {props.user.team}
              </div>

              {/* <div>
                <div className="flex flex-col space-y-3 my-4 items-center justify-center">
                  <div className="text-black font-semibold text-xl">
                    Feedback Type
                  </div>
                  <div className="flex space-x-2 justify-center items-center w-full">
                    <button
                      type="button"
                      onClick={() => setFeedbackType("text")}
                      className={`${
                        feedbackType !== "text" ? "bg-gray-500" : "bg-blue-500"
                      } w-full rounded-md px-4 py-2 text-white font-bold `}
                    >
                      <div>TEXT</div>
                    </button>

                    <button
                      type="button"
                      onClick={() => setFeedbackType("video")}
                      className={`${
                        feedbackType !== "video" ? "bg-gray-500" : "bg-blue-500"
                      } w-full rounded-md px-4 py-2 text-white font-bold `}
                    >
                      <div>VIDEO</div>
                    </button>
                  </div>
                </div>
              </div> */}
            </div>

            {feedbackType === "text" && (
              <>
                <div className="w-full">
                  <div className="mb-1">
                    1. How do you feel about the overall direction outlined by this
                    pillar ?
                  </div>
                  <div className="grid md:grid-cols-2 gap-2">
                    <button
                     type="button"
                      onClick={() => setFeel("Excited")}
                      className={`${
                        feel === "Excited"
                          ? "bg-blue-500 border-b-4 border-blue-700"
                          : "bg-gray-500 border-b-4 border-gray-700"
                      }   w-full rounded-md px-4 py-2 text-white font-bold  flex flex-col items-center`}
                    >
                      <div className="uppercase">Excited</div>
                    </button>

                    <button
                     type="button"
                     onClick={() => setFeel("Optimistic")}
                     className={`${
                       feel === "Optimistic"
                       ? "bg-blue-500 border-b-4 border-blue-700"
                       : "bg-gray-500 border-b-4 border-gray-700"
                     }  w-full rounded-md px-4 py-2 text-white font-bold  flex flex-col items-center`}
                   >
                     <div className="uppercase">Optimistic</div>
                   </button>

                   <button
                     type="button"
                     onClick={() => setFeel("Uncertain")}
                     className={`${
                       feel === "Uncertain"
                       ? "bg-blue-500 border-b-4 border-blue-700"
                       : "bg-gray-500 border-b-4 border-gray-700"
                     }  w-full rounded-md px-4 py-2 text-white font-bold  flex flex-col items-center`}
                   >
                     <div className="uppercase">Uncertain</div>
                   </button>

                   <button
                     type="button"
                     onClick={() => setFeel("Concerned")}
                     className={`${
                       feel === "Concerned"
                       ? "bg-blue-500 border-b-4 border-blue-700"
                       : "bg-gray-500 border-b-4 border-gray-700"
                     }  w-full rounded-md px-4 py-2 text-white font-bold  flex flex-col items-center`}
                   >
                     <div className="uppercase">Concerned</div>
                   </button>
                  </div>
                </div>

                <hr className="border w-full h-[1px] my-4" />

                <div className="w-full">
                  <div className="mb-1">
                    2. How do you think this pillar will impact your day-to-day work ?
                  </div>
                  <div className="grid md:grid-cols-2 gap-2">
                    <button
                     type="button"
                      onClick={() => setImpact("Positively")}
                      className={`${
                        impact === "Positively"
                        ? "bg-blue-500 border-b-4 border-blue-700"
                        : "bg-gray-500 border-b-4 border-gray-700"
                      }  w-full rounded-md px-4 py-2 text-white font-bold  flex flex-col items-center`}
                    >
                      <div className="uppercase">Positively</div>
                    </button>

                    <button
                     type="button"
                     onClick={() => setImpact("Neutral")}
                     className={`${
                      impact === "Neutral"
                      ? "bg-blue-500 border-b-4 border-blue-700"
                      : "bg-gray-500 border-b-4 border-gray-700"
                     }  w-full rounded-md px-4 py-2 text-white font-bold  flex flex-col items-center`}
                   >
                     <div className="uppercase">Neutral</div>
                   </button>

                   <button
                     type="button"
                     onClick={() => setImpact("Negative")}
                     className={`${
                      impact === "Negative"
                      ? "bg-blue-500 border-b-4 border-blue-700"
                      : "bg-gray-500 border-b-4 border-gray-700"
                     }  w-full rounded-md px-4 py-2 text-white font-bold  flex flex-col items-center`}
                   >
                     <div className="uppercase">Negative</div>
                   </button>

                   
                  </div>
                </div>

                <hr className="border w-full h-[1px] my-4" />

                <div className="w-full">
                  <div className="mb-1">
                    3. Do you have any suggestions for improving the execution of
                    the strategic plan’s pillars within the organization?
                  </div>
                  <textarea
                    autoComplete="off"
                    role="presentation"
                    rows={5}
                    className="border-2 px-2  rounded w-full"
                    {...register("feedback", { required: false })}
                  ></textarea>
                  {/* <div>Length {watch('feedback')?.length}/{props.setting?.maximum_feedback_text}</div> */}
                </div>
              </>
            )}

            {feedbackType === "video" && (
              <RecordVideo
                timer={parseInt(props?.setting?.maximum_record_video || 0)}
                setVideoRecord={(val) => setVideoRecord(val)}
              />
            )}
          </div>
        )}

        {checkAllow === "not-allowed-to-add" && (
          <div className="w-full">
            <div className="bg-red-500 w-full text-center py-2 px-2 text-white font-bold rounded">
              Haven't Checked in
            </div>

            <div className="text-center text-xl font-bold text-black uppercase mt-4">
              {props.user.name}
            </div>
            <div className="text-center text-xl font-bold text-black uppercase mt-2 mb-4">
              <span className="text-sm">TEAM :</span>
              <br />
              {props.user.team}
            </div>
          </div>
        )}

        <div className="text-center font-bold text-red-500">{msg}</div>

        <>
          <div className="grid grid-cols-2 gap-4 w-full">
            <button
              type="button"
              onClick={() => props.ok()}
              className={` border-b-4 border-red-700 px-2 py-2 rounded-lg text-white font-bold bg-red-500  w-full text-center`}
            >
              CANCEL
            </button>

            <div className="flex space-x-5">
              <button
                type="submit"
                className={`border-b-4 border-green-700 px-2 py-2 rounded-lg text-white font-bold bg-green-500  w-full text-center`}
              >
                {loading ? "Loading..." : "CONFIRM"}
              </button>
            </div>
          </div>
        </>
      </form>
    </>
  );
};

export default FormRFIDResult;

import { useEffect, useState } from "react";
import QRScan from "../../components/qr_scan";
import { Controller, useForm } from "react-hook-form";
import CONFIG from "../../constanta/config";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import useUser from "../../hook/useUser";
import ReactSelect from "react-select";
import moment from "moment";
import { useQuery } from "../../hook/useQuery";
import helper from "../../utils/helper";
import imgLogo from "../../assets/logo-rbl.png";
import UpCountdown from "./timer_quiz";

const FormRFIDResult = (props) => {
  const [loading, setLoading] = useState(false);
  const query = useQuery();
  const [listGuest, setListGuest] = useState([]);
  const [pointUser, setPointUser] = useState(0);
  const [activity, setActivity] = useState("");
  const [msg, setMsg] = useState("");
  const [time, setTime] = useState("");

  const [listQ, setListQ] = useState([]);

  useEffect(() => {
    initQ();
  }, []);

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const initQ = () => {
    const q = [
      {
        id: 1,
        question:
          "Which pillar in our strategic plan speaks to managing our costs in a more purposeful way?",
        option: ["Mortgages", "Efficiency", "Trust and Wealth Management"],
        answer: "Efficiency",
        user_choose: "",
      },
      {
        id: 2,
        question:
          "What is the name of the Unit that will help staff to navigate the changes that will come during the next 5 years?",
        option: ["Mergers & Acquisitions", "Change Management", "Digital Lab"],
        answer: "Change Management",
        user_choose: "",
      },
      {
        id: 3,
        question:
          "Which office is going to keep us on track to ensure we execute our strategic plan?",
        option: ["Transformation Office", "Digital Lab", "Change Management"],
        answer: "Transformation Office",
        user_choose: "",
      },
      {
        id: 4,
        question: "How many pillars are there in our new strategic plan? ",
        option: ["8", "5", "6"],
        answer: "8",
        user_choose: "",
      },
      {
        id: 5,
        question: "Select 1 lever from this list in our strategic plan",
        option: [
          "Transformation Office",
          "Programmatic Mergers & Acquisitions",
          "Culture, Talent and Health Initiatives",
        ],
        answer: "Programmatic Mergers & Acquisitions",
        user_choose: "",
      },
      {
        id: 6,
        question: "Which is the new business line of the Group?",
        option: [
          "Corporate",
          "Republic Life Insurance",
          "Trust & Wealth Management ",
        ],
        answer: "Republic Life Insurance",
        user_choose: "",
      },
      {
        id: 7,
        question:
          "How will we ensure we focus on Mergers & Acquisitions in a more intentional way?",
        option: [
          "Establish a digital lab",
          "Appoint a Chief Information Officer",
          "Create an office for Mergers & Acquisitions",
        ],
        answer: "Create an office for Mergers & Acquisitions",
        user_choose: "",
      },
      {
        id: 8,
        question: "What are the key elements of our aspiration?",
        option: [
          "Human Development, Excellence in Execution, Innovation and Strategic Expansion",
          "Culture, Commercial and Operational Excellence, Technology Consolidation, Expansion",
          "Strategic expansion, Wealth Management, Marketing, Efficiency",
        ],
        answer:
          "Human Development, Excellence in Execution, Innovation and Strategic Expansion",
        user_choose: "",
      },
      {
        id: 9,
        question: "Select the 3 enablers in the strategic plan:",
        option: [
          "Commercial and Operational Excellence, Digital Transformation, Programmatic Mergers and Acquisitions",
          "Execution mechanism (bottom up plan, new operating model and transformation office), Culture, Talent and Health initiatives, Technology Consolidation",
          "Strategic expansion, Wealth Management, Marketing",
        ],
        answer:
          "Execution mechanism (bottom up plan, new operating model and transformation office), Culture, Talent and Health initiatives, Technology Consolidation",
        user_choose: "",
      },
      {
        id: 10,
        question:
          "In the Retail & Commercial pillar, name 3 deliverables for the next 5 years?",
        option: [
          "Cross Selling, Develop new products, Using AI for marketing",
          "Develop new products, Wealth & Asset Management Software, Implementation of IVR system",
          "Using AI for marketin, Process Automation, Introducing Sector Specialist Corporate Teams",
        ],
        answer: "Cross Selling, Develop new products, Using AI for marketing",
        user_choose: "",
      },
    ];

    let rnd = shuffleArray(q);
    let qFix = rnd.splice(0, Math.min(7, rnd.length));
    qFix = qFix.map((item) => {
      return {
        ...item,
        option: shuffleArray(item.option),
      };
    });

    setListQ(qFix);
  };

  const onSubmit = () => {
    if (loading) return;

    setLoading(true);

    handleSubmit();
  };


  const handleSubmit = () => {
    setMsg("");

    let score = 0;

    let i =0;
    while(i< listQ.length){
      if(listQ[i].answer === listQ[i].user_choose){
        score++;
      }

      i++;
    }

    const payload = {
      id_user: props?.user?.id_user,
      quiz_data: JSON.stringify(listQ),
      quiz_time: time,
      score: score,
    };


    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    try {
      axios
        .post(CONFIG.URL + "/quiz/submit", form_data, {
          headers: {
            token: Cookies.get("token"),
          },
        })

        .then(function (response) {
          setLoading(false);
          if (response?.data.status === "SUCCESS") {
            props.onSuccess();
          } else {
            
          }
        })
        .catch(function (error) {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  };

  const chooseOption = (idQ, choose) => {
    let arr = [...listQ];
    let q = arr.find((v) => v.id === idQ);

    q.user_choose = choose;

    setListQ(arr);
  };

  const canSubmit = () => {
    let empty = listQ.find((v) => v.user_choose === "");
    if (empty !== undefined) {
      return false;
    } else {
      return true;
    }
  };
  return (
    <>
      <div className="space-y-3 px-5 py-3 bg-white rounded-md items-center justify-center flex flex-col w-full md:w-1/2 transition-all">
        <div className="text-center font-bold">
          <div className="w-full flex justify-center items-center">
            <img className="w-20 h-20 rounded-md mb-2" src={imgLogo} />
          </div>
          <div className="text-black font-semibold text-xl mb-2">
            Bonus Quiz
          </div>
          <div className="w-full">
            <div className="w-full">
              <div className="text-center text-xl font-bold text-black uppercase">
                {props.user.name}
              </div>
              <div className="text-center text-xl font-bold text-black uppercase mt-2">
                <span className="text-sm">TEAM :</span>
                <br />
                {props.user.team}
              </div>
            </div>
          </div>
        </div>

        <div>
          <UpCountdown setTime={(val) => setTime(val)} />
        </div>

        {/* LIST QUESTION */}
        {listQ?.map((item, key) => {
          return (
            <div key={key} className="text-sm border rounded-md p-3">
              <div className="font-semibold flex space-x-2">
                <div>{key + 1}.</div>
                <div>{item.question}</div>
              </div>
              <div className="mt-2 space-y-1">
                {item?.option?.map((subitem, subkey) => {
                  return (
                    <button
                      key={subkey}
                      type="button"
                      onClick={() => chooseOption(item.id, subitem)}
                      className={`${
                        item.user_choose === subitem
                          ? "bg-green-500"
                          : "bg-gray-500"
                      } min-w-[160px] px-2 py-2 rounded-lg text-white font-bold   w-full text-center`}
                    >
                      {subitem}
                    </button>
                  );
                })}
              </div>
            </div>
          );
        })}

        <div className="text-center font-bold text-red-500">{msg}</div>

        <>
          <div className="mx-auto flex justify-center items-center space-x-4">
            <div className="flex space-x-5">
              <button
                disabled={!canSubmit()}
                type="button"
                onClick={() => onSubmit()}
                className={`${
                  canSubmit() ? "bg-green-500" : "bg-gray-500"
                } min-w-[160px] px-10 py-2 rounded-lg text-white font-bold   w-full text-center`}
              >
                {loading ? "LOADING..." : "SUBMIT"}
              </button>
            </div>
          </div>
        </>
      </div>
    </>
  );
};

export default FormRFIDResult;

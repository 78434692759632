import axios from "axios";
import CONFIG from "../../constanta/config";
import helper from "../../utils/helper";
import { useEffect, useState } from "react";
import imgLogo from "../../assets/logo-rbl.png";

const PageQuizLeaderboard = (props) => {
  const [leaderboard, setLeaderboard] = useState([]);
  const [count, setCount] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount(count + 1);
      getLeaderboard();
    }, 1000 * 10);

    return () => clearInterval(interval);
  }, [count]);

  useEffect(() => {
    getLeaderboard();
  }, []);

  const getLeaderboard = async () => {
    const response = await axios.get(CONFIG.URL + "/quiz/leaderboard");

    if (response?.data?.status === "SUCCESS") {
      let arr = response?.data?.data?.map((item) => {
        return {
          ...item,
        };
      });

      arr.sort((a, b) => b.total - a.total);

      setLeaderboard(arr);
    }
  };

  return (
    <>
      {
        <div
          className="w-full min-h-screen flex flex-col justify-start items-start bg-gray-200 px-5 bg-cover bg-no-repeat py-5"
          style={{
            backgroundPosition: "center",
            backgroundImage: `url('${helper.getBackground(
              props?.setting?.background
            )}')`,
          }}
        >
          <div className="flex flex-col justify-center items-center py-10 w-full bg-white px-4 rounded-md bg-opacity-50 backdrop-blur-sm">
            <div className="w-full ">
              <div className="w-full flex justify-center items-center">
                <img className="w-20 h-20 rounded-md mb-4" src={imgLogo} />
              </div>
              <div className="text-center text-xl font-bold text-black uppercase">
                RBL STAFF RALLY 2024
              </div>
              <div className="text-center text-xl font-bold text-black uppercase">
                Leaderboard Quiz
              </div>

              <div className="text-center text-sm  text-black ">
                (Scroll Right To See Full Results &#10140;)
              </div>

              <div className="w-full overflow-scroll h-max  my-5 ">
                <div className="flex flex-col w-full">
                  {/* HEADER */}
                  <div className="flex w-full font-bold text-white">
                    <div className="flex flex-none min-w-[100px] uppercase text-center  border border-white bg-black px-4 py-2">
                      Rank
                    </div>
                    <div className="flex flex-grow min-w-[340px] uppercase text-left border border-white  bg-black px-4 py-2">
                      Name
                    </div>
                    <div className="flex flex-none min-w-[520px] justify-center items-center uppercase text-center border border-white  bg-black px-4 py-2">
                      Branch
                    </div>
                    <div className="flex flex-none min-w-[360px] justify-center items-center uppercase text-center border border-white  bg-black px-4 py-2">
                      Team
                    </div>

                    <div className="flex flex-none w-[120px] justify-center items-center uppercase text-center border border-white  bg-black px-4 py-2">
                      Correct
                    </div>

                    <div className="flex flex-none w-[150px] justify-center items-center uppercase text-center border border-white  bg-black px-4 py-2">
                      Time Taken
                    </div>
                  </div>

                  <div className="max-h-[40vh] ">
                    {leaderboard?.map((item, key) => {
                      return (
                        <div
                          key={key}
                          className={`flex w-full font-bold text-white`}
                        >
                          <div
                            className={`${
                              key % 2 === 0 ? "bg-[#156082]" : "bg-[#0e4b66]"
                            }  flex flex-none min-w-[100px] uppercase text-center  border border-white  px-4 py-2`}
                          >
                            {key + 1}
                          </div>
                          <div
                            className={`${
                              key % 2 === 0 ? "bg-[#156082]" : "bg-[#0e4b66]"
                            }  flex flex-grow min-w-[340px] uppercase text-left border border-white  px-4 py-2`}
                          >
                            {item.name}
                          </div>

                          <div
                            className={`${
                              key % 2 === 0 ? "bg-[#156082]" : "bg-[#0e4b66]"
                            }  flex flex-none min-w-[520px] uppercase text-center  border border-white  px-4 py-2`}
                          >
                            {item.branch}
                          </div>

                          <div
                            className={`${
                              key % 2 === 0 ? "bg-[#156082]" : "bg-[#0e4b66]"
                            }  flex flex-none min-w-[360px] uppercase text-center  border border-white  px-4 py-2`}
                          >
                             {item.team}
                          </div>

                          <div
                            className={`${
                              key % 2 === 0 ? "bg-[#156082]" : "bg-[#0e4b66]"
                            }  flex items-center justify-center flex-none min-w-[120px] uppercase text-center  border border-white  px-4 py-2`}
                          >
                             {item.score}
                          </div>


                          <div
                            className={`${
                              key % 2 === 0 ? "bg-[#156082]" : "bg-[#0e4b66]"
                            }  flex items-center justify-center flex-none min-w-[150px] uppercase text-center  border border-white  px-4 py-2`}
                          >
                             {item.quiz_time}
                          </div>

                          
                         
                        </div>
                      );
                    })}
                  </div>
                  
                </div>
              </div>

              <>
                <div className="mx-auto flex justify-center items-center space-x-4">
                  <a
                    href="./home"
                    type="button"
                    className={`border-b-4 border-red-700 px-10 py-2 rounded-lg text-white font-bold bg-red-500  w-max text-center`}
                  >
                    BACK
                  </a>
                </div>
              </>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default PageQuizLeaderboard;

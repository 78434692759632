import { useEffect, useState } from "react";
import axios from "axios";
import CONFIG from "../../constanta/config";
import Cookies from "js-cookie";
const PopupConfirmRedeem = (props) => {
    const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");

    const handleRedeemPoint = () => {
      setLoading(true)
        setMsg("");
        const payload = {
          id_user: props?.user?.id_user,
          point_user: props.item.point,
          redeem_item: props.item.name
        };
    
        var form_data = new FormData();
    
        for (var key in payload) {
          form_data.append(key, payload[key]);
        }
    
        try {
          axios
            .post(CONFIG.URL + "/event/point/redeem", form_data, {
              headers: {
                token: Cookies.get("token"),
              },
            })
    
            .then(function (response) {
              setLoading(false);
              if (response?.data.status === "SUCCESS") {
                props.calcUsedPoint(props.item.point);
              } else {
                setMsg("Error: " + response.data?.message);
              }
            })
            .catch(function (error) {
              setLoading(false);
            });
        } catch (error) {
          setLoading(false);
        }
      };

    return (
      <div className="fixed top-0 left-0 bg-[#0000009c] w-screen h-screen flex justify-center items-center">
        <div className="bg-white p-4 rounded-md w-[80%]">
          <div className="text-center w-full mb-4 font-bold">REDEEM</div>

          <div className="flex flex-col justify-center items-center font-bold my-6 text-2xl">
            <div className="uppercase">{props.item?.name}</div>
            <div>{props.item?.point} Point</div>
            
          </div>

          <div className="text-center font-bold text-red-500 mb-5">{msg}</div>


          <div className="mx-auto flex justify-center items-center space-x-4">
            <button
              disabled={loading}
              type="button"
              onClick={() => props.onClose()}
              className={`px-4 py-2 rounded-lg text-white font-bold bg-red-500  w-full text-center`}
            >
              CANCEL
            </button>

            <button
              type="button"
              onClick={() => handleRedeemPoint()}
              className={` px-4 py-2 rounded-lg text-white font-bold bg-green-500  w-full text-center`}
            >
              {loading ? "LOADING..." : "CONFIRM"}
            </button>
          </div>
        </div>
      </div>
    );
}

export default PopupConfirmRedeem;
import { useQuery } from "../../hook/useQuery";
import helper from "../../utils/helper";
import InfoCurrentCheckin from "./InfoCurrentCheckin";

const PageCheckinCurrent = (props) => {
  const query = useQuery();

  const getBG = () => {
    
    switch (query.get("id_gate")) {
      case "1":
        return props?.setting?.background;

      case "2":
        return props?.setting?.bg_pillar_1;
      case "3":
        return props?.setting?.bg_pillar_2;
      case "4":
        return props?.setting?.bg_pillar_3;
      case "5":
        return props?.setting?.bg_pillar_4;
      case "6":
        return props?.setting?.bg_pillar_5;
      case "7":
        return props?.setting?.bg_pillar_6;

      default:
       return props?.setting?.background;
    }
  }

  return (
    <>
      {
        <div
          className="w-full min-h-screen flex flex-col justify-center items-center bg-gray-200 px-5 bg-cover bg-no-repeat"
          style={{
            backgroundPosition: "center",
            backgroundImage: `url('${helper.getBackground(
              getBG()
            )}')`,
          }}
        >
          <div className="flex flex-col justify-center items-center py-10 w-full">
            <div className="flex flex-col justify-center items-center space-y-2.5 w-full">
              <InfoCurrentCheckin  />
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default PageCheckinCurrent;

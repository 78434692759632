import { useRecordWebcam } from "react-record-webcam";
import CountdownTimer from "./countdown-timer";
import { useState } from "react";

const RecordVideo = (props) => {
    const [time, setTime] = useState(props?.timer);

    const {
        createRecording,
        openCamera,
        closeCamera,
        startRecording,
        stopRecording,
        activeRecordings,
        clearAllRecordings
      } = useRecordWebcam();

      async function record() {
        await clearAllRecordings();
    
        const recording = await createRecording();
    
        await openCamera(recording.id);
        await startRecording(recording.id);
        await new Promise((resolve) => setTimeout(resolve, 1000 * time)); // Record for 3 seconds
        const recorded = await stopRecording(recording.id);
        props.setVideoRecord(recorded);
        await closeCamera(recording.id);
    
        // Upload the blob to a back-end
        // const formData = new FormData();
        // formData.append('file', recorded.blob, 'recorded.webm');
    
        // const response = await fetch('https://your-backend-url.com/upload', {
        //     method: 'POST',
        //     body: formData,
        // });
    };

    return (
      <div className="w-full">
        {activeRecordings.map((recording) => {
          return (
            <div className="relative" key={recording.id}>
                {
                  recording.status.toLowerCase() === "recording"  &&  <CountdownTimer seconds={time} />
                }

              <video ref={recording.webcamRef} autoPlay />
              <video
                className="absolute top-0 left-0"
                ref={recording.previewRef}
                autoPlay
                loop
              />

              {recording.status.toLowerCase() !== "recording" && (
                <button
                  onClick={() => record()}
                  className={`bg-blue-500 w-full rounded-md px-4 py-2 text-white font-bold mt-10`}
                >
                  <div>TAKE AGAIN</div>
                </button>
              )}
            </div>
          );
        })}

        {activeRecordings?.length === 0 && (
          <button
            onClick={() => record()}
            className={`bg-blue-500 w-full rounded-md px-4 py-2 text-white font-bold mt-4`}
          >
            <div>RECORD VIDEO</div>
          </button>
        )}
      </div>
    );
}

export default RecordVideo;
import { useEffect, useRef, useState } from "react";
import QRScan from "../../components/qr_scan";
import { Controller, useForm } from "react-hook-form";
import CONFIG from "../../constanta/config";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import useUser from "../../hook/useUser";
import ReactSelect from "react-select";
import moment from "moment";
import { useQuery } from "../../hook/useQuery";
import { useRecordWebcam } from "react-record-webcam";
import RecordVideo from "./record";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile } from "@ffmpeg/util";
import imgLogo from "../../assets/logo-rbl.png";
import QRCode from "react-qr-code";
import { toPng } from "html-to-image";

const FormRFIDResult = (props) => {
  const [loading, setLoading] = useState(false);
  const query = useQuery();
  const [checkAllow, setCheckAllow] = useState("");
  const [showGuest, setShowGuest] = useState(false);
  const [listGuest, setListGuest] = useState([]);
  const [gate, setGate] = useState(null);
  const [feedbackType, setFeedbackType] = useState("text");
  const [msg, setMsg] = useState("");

  const [videoRecord, setVideoRecord] = useState(null);

  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (props.user.event_gate) {
      let _gate = props.user.event_gate?.find(
        (v) => v.id_gate === query.get("id_gate")
      );
      setGate(_gate);
    }
  }, [props?.user]);

  const renderActivity = (activity) => {
    switch (activity) {
      case "Pillar 1 - Activity":
        return "Pillar 1 - Digital Experience - VR Game";

      case "Pillar 2 - Activity":
        return "Pillar 2 - SME- 100,000 Pyramid Game";

      case "Pillar 3 - Activity":
        return "Pillar 3 - Cost Optimization - Lego Game";

      case "Pillar 3 - Activity - Robot":
        return "Pillar 3 - Cost Optimization - Robot Race Game";

      case "Pillar 4 - Activity":
        return "Pillar 4 - RLIC - Karaoke";

      case "Pillar 5 - Activity":
        return "Pillar 5 - Trust and Wealth Management - Taboo";

      case "Pillar 6 - Activity":
        return "Pillar 6 - Mergers and Acquisition - Jeopardy";

      case "Pillar 1 - Bonus":
        return "Pillar 1 - Feedback Text";

      case "Pillar 2 - Bonus":
        return "Pillar 2 - Feedback Text";

      case "Pillar 3 - Bonus":
        return "Pillar 3 - Feedback Text";

      case "Pillar 4 - Bonus":
        return "Pillar 4 - Feedback Text";

      case "Pillar 5 - Bonus":
        return "Pillar 5 - Feedback Text";

      case "Pillar 6 - Bonus":
        return "Pillar 6 - Feedback Text";

      case "Pillar 6 - Bonus - Video":
        return "Pillar 6 - Feedback Video";

      default:
        return activity;
    }
  };

  const elementRef = useRef(null);

  const downloadQR = () => {
    toPng(elementRef.current, { cacheBust: false })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = `RBL2024_QR.png`;
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className="space-y-3 px-5 py-3 bg-white bg-opacity-50 backdrop-blur-sm rounded-md items-center justify-center flex flex-col w-full transition-all">
        <div className="w-full ">
          <div className="w-full flex justify-center items-center">
            <img className="w-20 h-20 rounded-md mb-4" src={imgLogo} />
          </div>

          <div className="text-center text-xl font-bold text-black uppercase">
            {props.user.name}
          </div>
          <div className="text-center text-xl font-bold text-black uppercase mt-2">
            <span className="text-sm">TEAM :</span> <br />
            {props.user.team}
          </div>

          <div className="text-black text-center font-semibold text-xl mt-2">
            <span className="font-bold">Your QR CODE</span>
          </div>

          <div ref={elementRef} className="w-full mx-auto my-10 flex justify-center items-center max-w-sm p-5 bg-white">
            <QRCode
              size={256}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              value={props?.user?.email}
            />
          </div>
          <button
            type="button"
            onClick={() => downloadQR()}
            className={`mb-10 border-b-4 border-blue-700 px-2 py-2 rounded-lg text-white font-bold bg-blue-500  w-full text-center`}
          >
            DOWNLOAD QR
          </button>
        </div>

        <>
          <div className="mx-auto flex justify-center items-center space-x-4">
            <button
              type="button"
              onClick={() => props.ok()}
              className={`border-b-4 border-red-700 px-10 py-2 rounded-lg text-white font-bold bg-red-500  w-full text-center`}
            >
              BACK
            </button>
          </div>
        </>
      </div>
    </>
  );
};

export default FormRFIDResult;

import React, { useState, useEffect } from "react";

function UpCountdown(props) {
  const [time, setTime] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setTime((prevTime) => prevTime + 1);
    }, 1000);

    // Clean up the interval when component unmounts
    return () => clearInterval(timer);
  }, []);

  // Format the time to display minutes and seconds
  const formatTime = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  const handleTime = () => {
    let v = formatTime(time);
    props.setTime(v)
    return v;
  };

  return (
    <div>
      <div className="flex justify-center items-center font-semibold">
        TIME: {handleTime()}
      </div>
    </div>
  );
}

export default UpCountdown;

import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import PageHome from "./pages/home";
import PageCheckin from "./pages/checkin";
import PageRedeem from "./pages/redeem";
import PageLogin from "./pages/login";
import PageEvent from "./pages/event";
import StatusInet from "./components/status_inet";
import PageRegistration from "./pages/registration";
import PageDay1 from "./pages/home/day1";
import PageDay2 from "./pages/home/day2";
import axios from "axios";
import CONFIG from "./constanta/config";
import Cookies from "js-cookie";
import PageRSVP from "./pages/rsvp";
import PageCheckinOffline from "./pages/checkin_offline";
import PageAddUser from "./pages/add_user";
import PageExit from "./pages/exit";
import PageSurvey from "./pages/survey";
import PageDistribution from "./pages/distribution";
import PageCheckoutOffline from "./pages/checkout";
import PageCheckinMobile from "./pages/checkin_mobile";
import PageLoginVoting from "./pages/login-voting";
import PageVoting from "./pages/voting";
import PageCheckinGeneral from "./pages/checkin_general";
import PageCheckoutGeneral from "./pages/checkout_general";
import PageCheckinCurrent from "./pages/checkin_current";
import PageAddPoint from "./pages/add_point";
import PageReentryMobile from "./pages/reentry_mobile";
import PageAddFeedback from "./pages/add_feedback";
import PageRedeemPoint from "./pages/redeem_point";
import PagePointsChecker from "./pages/points_checker";
import PagePointsUser from "./pages/points_user";
import PageTeamLeaderboard from "./pages/points_team_leaderboard";
import PageDoorprize from "./pages/doorprize";
import PageUserLeaderboard from "./pages/points_user_leaderboard";
import PageQuiz from "./pages/quiz";
import PageSurveyOverall from "./pages/survey_overall";
import PageQuizLeaderboard from "./pages/points_quiz_leaderboard";
import PageQRCodeUser from "./pages/qrcode_user";

function App() {
  const [totalCheckin, setTotalCheckin] = useState(0);
  const [totalCheckout, setTotalCheckout] = useState(0);
  const [totalRedeem, setTotalRedeem] = useState(0);
  const [setting, setSetting] = useState(null);

  useEffect(() => {
    // checkLocal();
    getListSetting();
  }, []);

  const getListSetting = () => {
    axios
      .get(CONFIG.URL + "/config")
      .then(function (response) {
        if (response?.data.status === "SUCCESS") {
          setSetting(response?.data?.config);
        } else {
        }
      })
      .catch(function (error) {});
  };

  const checkLocalCheckin = () => {
    let checkin = window.localStorage.getItem("LocalCheckin");
    if (checkin) {
      setTotalCheckin(JSON.parse(checkin).length);
    } else {
      setTotalCheckin(0);
    }
  };

  const checkLocalCheckout = () => {
    let checkout = window.localStorage.getItem("LocalCheckout");
    if (checkout) {
      setTotalCheckout(JSON.parse(checkout).length);
    } else {
      setTotalCheckout(0);
    }
  };

  const checkLocalRedeem = () => {
    let redeem = window.localStorage.getItem("LocalRedeem");
    if (redeem) {
      setTotalRedeem(JSON.parse(redeem).length);
    } else {
      setTotalRedeem(0);
    }
  };

  const checkLocal = () => {
    checkLocalCheckin();
    checkLocalCheckout();
    checkLocalRedeem();
  };

  return (
    <>
      {/* <StatusInet
        totalCheckin={totalCheckin}
        totalCheckout={totalCheckout}
        totalRedeem={totalRedeem}
        checkLocal={() => checkLocal()}
      />
      <div className="pt-8"></div> */}

      <div className="overflow-y-auto">
        <Routes>
          <Route path="/" element={<PageLogin setting={setting} />} />
          <Route path="/home" element={<PageHome setting={setting} />} />
          <Route path="/login" element={<PageLogin setting={setting} />} />

          <Route path="/gate" element={<PageEvent setting={setting} />} />

          {/* <Route
            path="/checkin"
            element={<PageCheckinGeneral setting={setting} />}
          /> */}

          <Route
            path="/checkout"
            element={<PageCheckoutGeneral setting={setting} />}
          />

          <Route
            path="/checkin-mobile"
            element={<PageCheckinMobile setting={setting} />}
          />

          <Route
            path="/reentry-mobile"
            element={<PageReentryMobile setting={setting} />}
          />

          <Route
            path="/checkin-current"
            element={<PageCheckinCurrent setting={setting} />}
          />

          <Route
            path="/add-point"
            element={<PageAddPoint setting={setting} />}
          />

          <Route
            path="/add-feedback"
            element={<PageAddFeedback setting={setting} />}
          />

          <Route
            path="/redeem-point"
            element={<PageRedeemPoint setting={setting} />}
          />

          <Route
            path="/points-checker"
            element={<PagePointsChecker setting={setting} />}
          />

          <Route
            path="/points-user"
            element={<PagePointsUser setting={setting} />}
          />

          <Route
            path="/leaderboard"
            element={<PageTeamLeaderboard setting={setting} />}
          />

          <Route
            path="/leaderboard-user"
            element={<PageUserLeaderboard setting={setting} />}
          />

          <Route
            path="/doorprize"
            element={<PageDoorprize setting={setting} />}
          />

          <Route path="/quiz" element={<PageQuiz setting={setting} />} />
          <Route path="/quiz-leaderboard" element={<PageQuizLeaderboard setting={setting} />} />
          <Route path="/survey-overall" element={<PageSurveyOverall setting={setting} />} />
          <Route path="/qrcode" element={<PageQRCodeUser setting={setting} />} />
        </Routes>
      </div>
    </>
  );
}

export default App;

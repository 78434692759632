import { useEffect, useState } from "react";
import QRScan from "../../components/qr_scan";
import { Controller, useForm } from "react-hook-form";
import CONFIG from "../../constanta/config";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import useUser from "../../hook/useUser";
import ReactSelect from "react-select";
import moment from "moment";

const FormThankyouRFID = (props) => {
  useEffect(() => {
    setTimeout(() => {
      props?.ok()
    }, 500);
  }, [])
  return (
    <>
      <div className="space-y-5 px-5 py-10 bg-white rounded-md w-full md:w-1/2 transition-all">
        <div className="bg-green-500 w-full text-center py-5 text-white font-bold rounded">
          SUCCESS SUBMIT SURVEY
        </div>

        {/* <div className="mx-auto flex justify-center items-center">
          <button
            type="button"
            onClick={() => props.ok()}
            className={`mt-10 w-[340px]  px-10 py-2 rounded-lg text-white font-bold bg-green-500  w-full text-center`}
          >
            OK
          </button>
        </div> */}
      </div>
    </>
  );
};

export default FormThankyouRFID;

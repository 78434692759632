import { useEffect, useState } from "react";
import QRScan from "../../components/qr_scan";
import { Controller, useForm } from "react-hook-form";
import CONFIG from "../../constanta/config";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import useUser from "../../hook/useUser";
import ReactSelect from "react-select";
import useReport from "../../hook/useReport";
import moment from "moment";
import { useQuery } from "../../hook/useQuery";
import helper from "../../utils/helper";

const Doorprize = (props) => {
  const [user, setUser] = useState("");

  useEffect(() => {
    if (props.user?.length !== 0) {
      const names = props?.user; // Daftar nama yang akan dipilih secara acak
      const interval = setInterval(() => {
        const randomIndex = Math.floor(Math.random() * names.length);
        const randomName = names[randomIndex];
        setUser(randomName);
      }, 50); // Interval waktu dalam milidetik, contoh: setiap 3 detik

      // Membersihkan interval saat komponen tidak lagi dirender
      return () => clearInterval(interval);
    }
  }, [props.user]); // Dependensi kosong agar useEffect hanya dijalankan sekali saat komponen dimuat

  return (
    <>
      <div className="relative flex justify-center items-center h-full  px-5 py-10 bg-white bg-opacity-50 backdrop-blur-sm rounded-md w-full md:w-3/4 z-0">
        <div className="text-center font-bold mb-10">
          {/* <div className="text-2xl">{focus}</div> */}

          <div className="text-black font-semibold mt-8 text-2xl uppercase">
            {props?.doorprize?.name}
          </div>

          <div className="text-black font-semibold mt-8 text-6xl">
            {user.name}
          </div>

          <button
            onClick={() => props.stop(user)}
            className={`mt-10 w-[160px] px-10 py-2 rounded-lg text-white font-bold bg-red-500 text-center`}
          >
            STOP
          </button>
        </div>
      </div>
    </>
  );
};

export default Doorprize;

import { useEffect, useState } from "react";
import QRScan from "../../components/qr_scan";
import { Controller, useForm } from "react-hook-form";
import CONFIG from "../../constanta/config";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import useUser from "../../hook/useUser";
import ReactSelect from "react-select";
import useReport from "../../hook/useReport";
import moment from "moment";
import { useQuery } from "../../hook/useQuery";
import helper from "../../utils/helper";

const DoorprizeWinner = (props) => {
  return (
    <>
      <div className="relative flex justify-center items-center h-full  px-5 py-10 bg-white bg-opacity-50 backdrop-blur-sm rounded-md w-full md:w-3/4 z-0">
        <div className="text-center font-bold mb-10">
          {/* <div className="text-2xl">{focus}</div> */}

          <div className="text-black font-semibold mt-8 text-2xl uppercase">
            {props?.doorprize?.name}
          </div>

          <div className="text-black font-semibold mt-8 text-6xl">
            {props.winner?.name}
          </div>
          <div className="text-black font-semibold mt-8 text-2xl">
            {props.winner?.team}
          </div>

          <button
            onClick={() => props.back()}
            className={`border-b-4 border-blue-700 mt-10 border-b-4 border-blue-700 min-w-[160px] px-10 py-2 rounded-lg text-white font-bold bg-blue-500 w-full text-center`}
          >
            BACK
          </button>
        </div>
      </div>
    </>
  );
};

export default DoorprizeWinner;

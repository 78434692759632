import { useEffect, useState } from "react";
import QRScan from "../../components/qr_scan";
import { Controller, useForm } from "react-hook-form";
import CONFIG from "../../constanta/config";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import useUser from "../../hook/useUser";
import ReactSelect from "react-select";
import useReport from "../../hook/useReport";
import moment from "moment";
import { useQuery } from "../../hook/useQuery";
import helper from "../../utils/helper";

const InfoCurrentCheckin = (props) => {
  const localReport = useReport();
  const [loading, setLoading] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [focus, setFocus] = useState("rfid");
  const [msg, setMsg] = useState("");
  const [count, setCount] = useState(0);
  const [showQR, setShowQR] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const query = useQuery();

  useEffect(() => {
    const interval = setInterval(() => {
      setCount(count + 1);
      handleCheckinTime();
    }, 1000 * 5);

    return () => clearInterval(interval);
  }, [count]);

  useEffect(() => {
    localReport.fetchData();
  }, []);

  const handleCheckinTime = async () => {
    const response = await axios.get(
      CONFIG.URL + "/event/report/currentcheckin"
    );

    let localTime = Cookies.get("local_time");
    if (localTime && localTime !== undefined && localTime !== "undefined") {
      if (moment(response?.data?.last_update) > moment(localTime)) {
        localReport.fetchData();
        Cookies.set("local_time", response?.data?.last_update);
      }
    } else {
      Cookies.set("local_time", response?.data?.last_update);
      localReport.fetchData();
    }
  };

  const calcCheckin = () => {
    if (localReport?.data) {
      const {
        checkin_main,
        checkout_main,
        reentry_main,

        checkin_pillar1,
        checkout_pillar1,
        reentry_pillar1,

        checkin_pillar2,
        checkout_pillar2,
        reentry_pillar2,

        checkin_pillar3,
        checkout_pillar3,
        reentry_pillar3,

        checkin_pillar4,
        checkout_pillar4,
        reentry_pillar4,

        checkin_pillar5,
        checkout_pillar5,
        reentry_pillar5,

        checkin_pillar6,
        checkout_pillar6,
        reentry_pillar6,
      } = localReport?.data;

      let total = 0;

      switch (query.get("id_gate").toLocaleLowerCase()) {
        case "1":
          total = checkin_main - checkout_main + reentry_main;
          break;
        case "2":
          total = checkin_pillar1 - checkout_pillar1 + reentry_pillar1;
          break;
        case "3":
          total = checkin_pillar2 - checkout_pillar2 + reentry_pillar2;
          break;
        case "4":
          total = checkin_pillar3 - checkout_pillar3 + reentry_pillar3;
          break;
        case "5":
          total = checkin_pillar4 - checkout_pillar4 + reentry_pillar4;
          break;
        case "6":
          total = checkin_pillar5 - checkout_pillar5 + reentry_pillar5;
          break;
        case "7":
          total = checkin_pillar6 - checkout_pillar6 + reentry_pillar6;
          break;

        default:
          total = 0;
          break;
      }

      if (total < 0) {
        return 0;
      } else {
        return total;
      }
    }

    return 0;
  };

  return (
    <>
      <div className="relative flex justify-center items-center h-full  px-5 py-10 bg-white bg-opacity-50 backdrop-blur-sm rounded-md w-full md:w-3/4 z-0">
        <div className="text-center font-bold mb-10">
          {/* <div className="text-2xl">{focus}</div> */}
          <div className="text-4xl uppercase mb-1">{query.get("gate")}</div>
          <div className="text-3xl uppercase mb-1">{helper.getPilarName(query.get("id_gate"))}</div>
          <div className="text-black font-semibold mt-8 text-3xl">
            Currently Checked In:
          </div>
          <div className="text-9xl mt-2">{calcCheckin() || 0}</div>
        </div>
      </div>
    </>
  );
};

export default InfoCurrentCheckin;

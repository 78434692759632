import { useEffect, useState } from "react";
import QRScan from "../../components/qr_scan";
import { Controller, useForm } from "react-hook-form";
import CONFIG from "../../constanta/config";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import useUser from "../../hook/useUser";
import ReactSelect from "react-select";
import moment from "moment";
import { useQuery } from "../../hook/useQuery";
import helper from "../../utils/helper";
import imgLogo from "../../assets/logo-rbl.png";
import UpCountdown from "./timer_quiz";

const FormRFIDResult = (props) => {
  const [loading, setLoading] = useState(false);
  const query = useQuery();
  const [listGuest, setListGuest] = useState([]);
  const [pointUser, setPointUser] = useState(0);
  const [activity, setActivity] = useState("");
  const [msg, setMsg] = useState("");
  const [time, setTime] = useState("");

  const [listQ, setListQ] = useState([]);

  useEffect(() => {
    initQ();
  }, []);

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const initQ = () => {
    const q = [
      {
        id: 1,
        question:
          "How would you rate the overall organization and flow of this event ? Scale of 1 – Poor to 5 Excellent",
        answer: "",
      },
      {
        id: 2,
        question:
          "Did the staff rally effectively communicate the vision of the bank for the next 5 years? Scale of 1 – Very Ineffective to 5 Very Effective",
        answer: "",
      },
      {
        id: 3,
        question:
          "Did you feel your questions and concerns were addressed satisfactorily during the event?",
        answer: "",
      },
      {
        id: 4,
        question:
          "How likely are you to apply the insights gained from the rally in your day-to-day work ? Scale of 1 – Unlikely to 5 Very Likely",
        answer: "",
      },
      {
        id: 5,
        question:
          "How do you feel about the overall direction outlined by the Bank’s Strategic Plan ?",
        answer: "",
      },
      {
        id: 6,
        question:
          "Which pillar of the strategic plan do you feel most excited about implementing in your role??",
        answer: "",
      },
    ];

    setListQ(q);
  };

  const onSubmit = () => {
    if (loading) return;

    setLoading(true);

    handleSubmit();
  };

  const handleSubmit = () => {
    setMsg("");

    const payload = {
      id_user: props?.user?.id_user,
      survey_data: JSON.stringify(listQ),
      point_user: 500
    };

    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    try {
      axios
        .post(CONFIG.URL + "/survey/submit", form_data, {
          headers: {
            token: Cookies.get("token"),
          },
        })

        .then(function (response) {
          setLoading(false);
          if (response?.data.status === "SUCCESS") {
            props.onSuccess();
          } else {
          }
        })
        .catch(function (error) {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  };

  const chooseOption = (idQ, choose) => {
    let arr = [...listQ];
    let q = arr.find((v) => v.id === idQ);

    q.answer = choose;

    setListQ(arr);
  };

  const canSubmit = () => {
    
    let empty = listQ.find((v) => v.answer === "");
    console.log(empty)
    if (empty !== undefined) {
      return false;
    } else {
      return true;
    }
  };
  return (
    <>
      <div className="space-y-3 px-5 py-3 bg-white rounded-md items-center justify-center flex flex-col w-full md:w-1/2 transition-all">
        <div className="text-center font-bold">
          <div className="w-full flex justify-center items-center">
            <img className="w-20 h-20 rounded-md mb-2" src={imgLogo} />
          </div>
          <div className="text-black font-semibold text-xl mb-2">Survey</div>
          <div className="w-full">
            <div className="w-full">
              <div className="text-center text-xl font-bold text-black uppercase">
                {props.user.name}
              </div>
              <div className="text-center text-xl font-bold text-black uppercase mt-2">
                <span className="text-sm">TEAM :</span>
                <br />
                {props.user.team}
              </div>
            </div>
          </div>
        </div>

        {/* LIST QUESTION */}
        {/* {listQ?.map((item, key) => {
          return (
            <div key={key} className="text-sm border rounded-md p-3">
              <div className="font-semibold flex space-x-2">
                <div>{key + 1}.</div>
                <div>{item.question}</div>
              </div>
              <div className="mt-2 space-y-1">
                {item?.option?.map((subitem, subkey) => {
                  return (
                    <button
                      key={subkey}
                      type="button"
                      onClick={() => chooseOption(item.id, subitem)}
                      className={`${
                        item.answer === subitem
                          ? "bg-green-500"
                          : "bg-gray-500"
                      } min-w-[160px] px-2 py-2 rounded-lg text-white font-bold   w-full text-center`}
                    >
                      {subitem}
                    </button>
                  );
                })}
              </div>
            </div>
          );
        })} */}

        <div className="text-sm border rounded-md p-3">
          <div className="font-semibold flex space-x-2">
            <div>1.</div>
            <div>{listQ[0]?.question}</div>
          </div>
          <div className="mt-2 space-y-1">
            <div className="grid grid-cols-5 gap-2 w-full">
              <button
                type="button"
                onClick={() => chooseOption(1, "1")}
                className={`${
                  listQ[0]?.answer === "1" ? "bg-green-500" : "bg-gray-500"
                }  px-2 py-2 rounded-lg text-white font-bold  w-full  text-center`}
              >
                1
              </button>
              <button
                type="button"
                onClick={() => chooseOption(1, "2")}
                className={`${
                  listQ[0]?.answer === "2" ? "bg-green-500" : "bg-gray-500"
                } px-2 py-2 rounded-lg text-white font-bold w-full  text-center`}
              >
                2
              </button>
              <button
                type="button"
                onClick={() => chooseOption(1, "3")}
                className={`${
                  listQ[0]?.answer === "3" ? "bg-green-500" : "bg-gray-500"
                } px-2 py-2 rounded-lg text-white font-bold w-full  text-center`}
              >
                3
              </button>
              <button
                type="button"
                onClick={() => chooseOption(1, "4")}
                className={`${
                  listQ[0]?.answer === "4" ? "bg-green-500" : "bg-gray-500"
                } px-2 py-2 rounded-lg text-white font-bold w-full  text-center`}
              >
                4
              </button>
              <button
                type="button"
                onClick={() => chooseOption(1, "5")}
                className={`${
                  listQ[0]?.answer === "5" ? "bg-green-500" : "bg-gray-500"
                } px-2 py-2 rounded-lg text-white font-bold w-full  text-center`}
              >
                5
              </button>
            </div>
          </div>
        </div>

        <div className="text-sm border rounded-md p-3">
          <div className="font-semibold flex space-x-2">
            <div>2.</div>
            <div>{listQ[1]?.question}</div>
          </div>
          <div className="mt-2 space-y-1">
            <div className="grid grid-cols-5 gap-2 w-full">
              <button
                type="button"
                onClick={() => chooseOption(2, "1")}
                className={`${
                  listQ[1]?.answer === "1" ? "bg-green-500" : "bg-gray-500"
                }  px-2 py-2 rounded-lg text-white font-bold  w-full  text-center`}
              >
                1
              </button>
              <button
                type="button"
                onClick={() => chooseOption(2, "2")}
                className={`${
                  listQ[1]?.answer === "2" ? "bg-green-500" : "bg-gray-500"
                } px-2 py-2 rounded-lg text-white font-bold w-full  text-center`}
              >
                2
              </button>
              <button
                type="button"
                onClick={() => chooseOption(2, "3")}
                className={`${
                  listQ[1]?.answer === "3" ? "bg-green-500" : "bg-gray-500"
                } px-2 py-2 rounded-lg text-white font-bold w-full  text-center`}
              >
                3
              </button>
              <button
                type="button"
                onClick={() => chooseOption(2, "4")}
                className={`${
                  listQ[1]?.answer === "4" ? "bg-green-500" : "bg-gray-500"
                } px-2 py-2 rounded-lg text-white font-bold w-full  text-center`}
              >
                4
              </button>
              <button
                type="button"
                onClick={() => chooseOption(2, "5")}
                className={`${
                  listQ[1]?.answer === "5" ? "bg-green-500" : "bg-gray-500"
                } px-2 py-2 rounded-lg text-white font-bold w-full  text-center`}
              >
                5
              </button>
            </div>
          </div>
        </div>

        <div className="text-sm border rounded-md p-3">
          <div className="font-semibold flex space-x-2">
            <div>3.</div>
            <div>{listQ[2]?.question}</div>
          </div>
          <div className="mt-2 space-y-1">
            <div className="grid grid-cols-2 gap-2 w-full">
              <button
                type="button"
                onClick={() => chooseOption(3, "YES")}
                className={`${
                  listQ[2]?.answer === "YES" ? "bg-green-500" : "bg-gray-500"
                }  px-2 py-2 rounded-lg text-white font-bold  w-full  text-center`}
              >
                YES
              </button>
              <button
                type="button"
                onClick={() => chooseOption(3, "NO")}
                className={`${
                  listQ[2]?.answer === "NO" ? "bg-green-500" : "bg-gray-500"
                } px-2 py-2 rounded-lg text-white font-bold w-full  text-center`}
              >
                NO
              </button>
              
            </div>
          </div>
        </div>

        <div className="text-sm border rounded-md p-3">
          <div className="font-semibold flex space-x-2">
            <div>4.</div>
            <div>{listQ[3]?.question}</div>
          </div>
          <div className="mt-2 space-y-1">
            <div className="grid grid-cols-5 gap-2 w-full">
              <button
                type="button"
                onClick={() => chooseOption(4, "1")}
                className={`${
                  listQ[3]?.answer === "1" ? "bg-green-500" : "bg-gray-500"
                }  px-2 py-2 rounded-lg text-white font-bold  w-full  text-center`}
              >
                1
              </button>
              <button
                type="button"
                onClick={() => chooseOption(4, "2")}
                className={`${
                  listQ[3]?.answer === "2" ? "bg-green-500" : "bg-gray-500"
                } px-2 py-2 rounded-lg text-white font-bold w-full  text-center`}
              >
                2
              </button>
              <button
                type="button"
                onClick={() => chooseOption(4, "3")}
                className={`${
                  listQ[3]?.answer === "3" ? "bg-green-500" : "bg-gray-500"
                } px-2 py-2 rounded-lg text-white font-bold w-full  text-center`}
              >
                3
              </button>
              <button
                type="button"
                onClick={() => chooseOption(4, "4")}
                className={`${
                  listQ[3]?.answer === "4" ? "bg-green-500" : "bg-gray-500"
                } px-2 py-2 rounded-lg text-white font-bold w-full  text-center`}
              >
                4
              </button>
              <button
                type="button"
                onClick={() => chooseOption(4, "5")}
                className={`${
                  listQ[3]?.answer === "5" ? "bg-green-500" : "bg-gray-500"
                } px-2 py-2 rounded-lg text-white font-bold w-full  text-center`}
              >
                5
              </button>
            </div>
          </div>
        </div>
        
        <div className="text-sm border rounded-md p-3">
          <div className="font-semibold flex space-x-2">
            <div>5.</div>
            <div>{listQ[4]?.question}</div>
          </div>
          <div className="mt-2 space-y-1">
            <div className="grid grid-cols-2 gap-2 w-full">
              <button
                type="button"
                onClick={() => chooseOption(5, "Excited")}
                className={`${
                  listQ[4]?.answer === "Excited" ? "bg-green-500" : "bg-gray-500"
                } uppercase  px-2 py-2 rounded-lg text-white font-bold  w-full  text-center`}
              >
                Excited 
              </button>
              <button
                type="button"
                onClick={() => chooseOption(5, "Optimistic")}
                className={`${
                  listQ[4]?.answer === "Optimistic" ? "bg-green-500" : "bg-gray-500"
                } uppercase px-2 py-2 rounded-lg text-white font-bold w-full  text-center`}
              >
                Optimistic
              </button>

              <button
                type="button"
                onClick={() => chooseOption(5, "Uncertain")}
                className={`${
                  listQ[4]?.answer === "Uncertain" ? "bg-green-500" : "bg-gray-500"
                } uppercase px-2 py-2 rounded-lg text-white font-bold w-full  text-center`}
              >
                Uncertain
              </button>

              <button
                type="button"
                onClick={() => chooseOption(5, "Concerned")}
                className={`${
                  listQ[4]?.answer === "Concerned" ? "bg-green-500" : "bg-gray-500"
                } uppercase px-2 py-2 rounded-lg text-white font-bold w-full  text-center`}
              >
                Concerned
              </button>
              
            </div>
          </div>
        </div>

        <div className="text-sm border rounded-md p-3">
          <div className="font-semibold flex space-x-2">
            <div>6.</div>
            <div>{listQ[5]?.question}</div>
          </div>
          <div className="mt-2 space-y-1">
            <div className="grid grid-cols-2 gap-2 w-full">
              <button
                type="button"
                onClick={() => chooseOption(6, "PILLAR 1")}
                className={`${
                  listQ[5]?.answer === "PILLAR 1" ? "bg-green-500" : "bg-gray-500"
                } uppercase  px-2 py-2 rounded-lg text-white font-bold  w-full  text-center`}
              >
                PILLAR 1 
              </button>
              <button
                type="button"
                onClick={() => chooseOption(6, "PILLAR 2")}
                className={`${
                  listQ[5]?.answer === "PILLAR 2" ? "bg-green-500" : "bg-gray-500"
                } uppercase px-2 py-2 rounded-lg text-white font-bold w-full  text-center`}
              >
                PILLAR 2 
              </button>

              <button
                type="button"
                onClick={() => chooseOption(6, "PILLAR 3")}
                className={`${
                  listQ[5]?.answer === "PILLAR 3" ? "bg-green-500" : "bg-gray-500"
                } uppercase px-2 py-2 rounded-lg text-white font-bold w-full  text-center`}
              >
                PILLAR 3
              </button>

              <button
                type="button"
                onClick={() => chooseOption(6, "PILLAR 4")}
                className={`${
                  listQ[5]?.answer === "PILLAR 4" ? "bg-green-500" : "bg-gray-500"
                } uppercase px-2 py-2 rounded-lg text-white font-bold w-full  text-center`}
              >
                PILLAR 4
              </button>

              <button
                type="button"
                onClick={() => chooseOption(6, "PILLAR 5")}
                className={`${
                  listQ[5]?.answer === "PILLAR 5" ? "bg-green-500" : "bg-gray-500"
                } uppercase px-2 py-2 rounded-lg text-white font-bold w-full  text-center`}
              >
                PILLAR 5
              </button>

              <button
                type="button"
                onClick={() => chooseOption(6, "PILLAR 6")}
                className={`${
                  listQ[5]?.answer === "PILLAR 6" ? "bg-green-500" : "bg-gray-500"
                } uppercase px-2 py-2 rounded-lg text-white font-bold w-full  text-center`}
              >
                PILLAR 6
              </button>
              
            </div>
          </div>
        </div>

        <div className="text-center font-bold text-red-500">{msg}</div>

        <>
          <div className="mx-auto flex justify-center items-center space-x-4">
            <div className="flex space-x-5">
              <button
                disabled={!canSubmit()}
                type="button"
                onClick={() => onSubmit()}
                className={`${
                  canSubmit() ? "bg-green-500" : "bg-gray-500"
                } min-w-[160px] px-10 py-2 rounded-lg text-white font-bold   w-full text-center`}
              >
                {loading ? "LOADING..." : "SUBMIT"}
              </button>
            </div>
          </div>
        </>
      </div>
    </>
  );
};

export default FormRFIDResult;

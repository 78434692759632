import { useEffect, useState } from "react";
import QRScan from "../../components/qr_scan";
import { Controller, useForm } from "react-hook-form";
import CONFIG from "../../constanta/config";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import useUser from "../../hook/useUser";
import ReactSelect from "react-select";
import moment from "moment";
import { useQuery } from "../../hook/useQuery";
import helper from "../../utils/helper";

const FormRFIDResult = (props) => {
  const [loading, setLoading] = useState(false);
  const query = useQuery();
  const [checkAllow, setCheckAllow] = useState("");
  const [showGuest, setShowGuest] = useState(false);
  const [listGuest, setListGuest] = useState([]);
  const [gate, setGate] = useState(null);
  const [pointUser, setPointUser] = useState(0);
  const [activity, setActivity] = useState("");
  const [msg, setMsg] = useState("");

  useEffect(() => {
    if (props.user.event_gate) {
      let _gate = props.user.event_gate?.find(
        (v) => v.id_gate === query.get("id_gate")
      );
      setGate(_gate);
    }
  }, [props?.user]);

  useEffect(() => {
    initAllowed();
  }, [gate]);

  // useEffect(() => {
  //   if (checkAllow !== "") {
  //     if (checkAllow === "allowed-to-checkin") {
  //       setTimeout(() => {
  //         handleCheckin();
  //       }, 1000);
  //       return;
  //     } else {
  //       if (checkAllow !== "allowed-to-re-checkin") {
  //         setTimeout(() => {
  //           window.location.reload();
  //         }, 1500);
  //         return;
  //       }
  //     }
  //   }
  // }, [checkAllow]);

  const togleGuest = (idx) => {
    let arr = [...listGuest];
    arr[idx].active = !arr[idx].active;
    setListGuest(arr);
  };

  const onSubmit = () => {
    if (loading) return;

    setLoading(true);

    handleAddPoint();
  };

  const getActivityLabel = () => {
    if (query.get("activity")) {
      return query.get("activity");
    } else {
      return "";
    }
  };

  const handleAddPoint = () => {
    setMsg("");
    const payload = {
      id_user: props?.user?.id_user,
      id_gate: query.get("id_gate") || "",
      point_user: pointUser,
      activity_label: getActivityLabel(),
    };

    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    try {
      axios
        .post(CONFIG.URL + "/event/point/add", form_data, {
          headers: {
            token: Cookies.get("token"),
          },
        })

        .then(function (response) {
          setLoading(false);
          if (response?.data.status === "SUCCESS") {
            props.onSuccess();
          } else {
            if (response?.data?.error_code === "no_point_added") {
              setMsg("Error: user has already earned points on this activity");
            } else {
              setMsg("Error: " + response.data?.message);
            }
          }
        })
        .catch(function (error) {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  };

  const initAllowed = () => {
    if (gate !== null) {
      if (gate?.checkin_time !== "0000-00-00 00:00:00") {
        setCheckAllow("allowed-to-add");
      } else {
        if (gate.checkout_time !== "0000-00-00 00:00:00") {
          if (moment(gate.reentry_time) > moment(gate.checkout_time)) {
            setCheckAllow("allowed-to-add");
          } else {
            setCheckAllow("not-allowed-to-add");
          }
        } else {
          setCheckAllow("not-allowed-to-add");
        }
      }
    }
  };

  const chooseActivity = (act, point) => {
    setActivity(act);
    setPointUser(point);
  };

  return (
    <>
      <div className="space-y-3 px-5 py-3 bg-white rounded-md items-center justify-center flex flex-col w-full md:w-1/2 transition-all">
        <div className="text-center font-bold">
          <div className="text-black font-semibold text-xl">Add Points</div>
          <div className="text-2xl uppercase">{query.get("gate")}</div>
          <div className="text-xl uppercase mb-1">
            {helper.getPilarName(query.get("id_gate"))}
          </div>
        </div>

        <hr className="border w-full h-[1px]" />

        {checkAllow === "allowed-to-add" && (
          <div>
            <div className="text-center text-xl font-bold text-black uppercase">
              {props.user.name}
            </div>
            <div className="text-center text-xl font-bold text-black uppercase mt-2 mb-4">
              <span className="text-sm">TEAM :</span>
              <br />
              {props.user.team}
            </div>

            <div>
              <div className="flex flex-col space-y-3 my-4 items-center justify-center">
                <div className="text-black font-semibold text-xl">
                  Select Points
                </div>

                {query.get("activity") !== "survey" && (
                  <button
                    onClick={() =>
                      chooseActivity(
                        "group",
                        props?.setting?.point_activity_team
                      )
                    }
                    className={`${
                      activity === "group" ? "bg-gray-500" : "bg-blue-500"
                    } w-full rounded-md px-4 py-2 text-white font-bold `}
                  >
                    <div>Participant</div>

                    <div className="text-2xl">
                      {props?.setting?.point_activity_team}
                    </div>
                  </button>
                )}

                <button
                  onClick={() =>
                    chooseActivity(
                      "individual",
                      props?.setting?.point_activity_individual
                    )
                  }
                  className={`${
                    activity === "individual" ? "bg-gray-500" : "bg-blue-500"
                  } w-full rounded-md px-4 py-2 text-white font-bold `}
                >
                  {query.get("activity") !== "survey" && <div>Winner</div>}
                  <div className="text-2xl">
                    {props?.setting?.point_activity_individual}
                  </div>
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="text-center font-bold text-red-500">{msg}</div>

        <>
          <div className="mx-auto flex w-full justify-center items-center space-x-4">
            <button
              type="button"
              onClick={() => props.ok()}
              className={`w-full px-10 py-2 rounded-lg text-white font-bold bg-red-500 text-center`}
            >
              CANCEL
            </button>

            {checkAllow === "allowed-to-add" && (
              <div className="flex space-x-5">
                <button
                  disabled={activity === ""}
                  type="button"
                  onClick={() => onSubmit()}
                  className={`${
                    activity !== "" ? "bg-green-500" : "bg-gray-500"
                  } px-10 py-2 rounded-lg text-white font-bold   w-full text-center`}
                >
                  {loading ? "LOADING..." : "CONFIRM"}
                </button>
              </div>
            )}
          </div>
        </>
      </div>
    </>
  );
};

export default FormRFIDResult;

import { useEffect, useState } from "react";
import { useQuery } from "../../hook/useQuery";
import helper from "../../utils/helper";
import Doorprize from "./doorprize";
import InfoCurrentCheckin from "./doorprize";
import DoorprizeIdle from "./doorprize_idle";
import DoorprizeWinner from "./doorprize_winner";
import CONFIG from "../../constanta/config";
import axios from "axios";
import Cookies from "js-cookie";

const PageDoorprize = (props) => {
  const query = useQuery();
  const [winner, setWinner] = useState([]);
  const [state, setState] = useState("idle"); //idle, play, result
  const [user, setUser] = useState([]);
  const [userWinner, setUserWinner] = useState([]);
  const [index, setIndex] = useState(0);
  const [doorprize, setDoorprize] = useState([]);
  const [loading, setLoading] = useState(true);

  const removeDuplicates = (array) => {
    return array.filter(
      (obj, index, self) =>
        index ===
        self.findIndex((t) => t.id === obj.id && t.email === obj.email)
    );
  };

  const getBG = () => {
    switch (query.get("id_gate")) {
      case "1":
        return props?.setting?.background;

      case "2":
        return props?.setting?.bg_pillar_1;
      case "3":
        return props?.setting?.bg_pillar_2;
      case "4":
        return props?.setting?.bg_pillar_3;
      case "5":
        return props?.setting?.bg_pillar_4;
      case "6":
        return props?.setting?.bg_pillar_5;
      case "7":
        return props?.setting?.bg_pillar_6;

      default:
        return props?.setting?.background;
    }
  };

  const removeDuplicatesFromArray = (arrayA, arrayB) => {
    return arrayA.filter(
      (objA) => !arrayB.some((objB) => objB.id_user === objA.id_user)
    );
  }

  useEffect(() => {
    if (props?.setting?.list_doorprize) {
      getLitsUser();
    }
  }, [props?.setting]);

  const getLitsUserWinner = async (userServer) => {

    const response = await axios.get(CONFIG.URL + `/event/doorprize?limit=10`, {
      headers: {
        token: Cookies.get("token"),
      },
    });

    if (response?.data?.status === "SUCCESS") {

      
      let arrWinner = response.data?.data;
      arrWinner = removeDuplicates(arrWinner);

      const arr = removeDuplicatesFromArray(userServer, arrWinner);
      setUser(arr);
    }
    else{
      setUser(userServer);
    }

    setLoading(false);
  };

  const getLitsUser = async () => {
    const response = await axios.get(
      CONFIG.URL +
        `/user?point=${props?.setting?.minimum_point_doorpize}&limit=10000000`,
      {
        headers: {
          token: Cookies.get("token"),
        },
      }
    );

    if (response?.data?.status === "SUCCESS") {
      getLitsUserWinner(response.data?.data);

      let prize = JSON.parse(props.setting?.list_doorprize);
      setDoorprize(prize);
    }

    setLoading(false);
  };

  const handleWinner = (user) => {
    handleAddWinner(user, index);
    winner.push(user);
    setWinner(winner);
    setState("result");
  };

  const handleAddWinner = async (data) => {
    const payload = {
      id_user: data?.id_user,
      prize: doorprize[index]?.name,
    };

    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    try {
      axios
        .post(CONFIG.URL + "/event/doorprize", form_data, {
          headers: {
            token: Cookies.get("token"),
          },
        })

        .then(function (response) {
          if (response?.data.status === "SUCCESS") {
            getLitsUserWinner(user);
          } else {
          }
        })
        .catch(function (error) {});
    } catch (error) {}
  };

  const handlePlay = () => {
    setState("play");
  };

  const handleBack = () => {
    setState("idle");
    setIndex(index + 1);
  };

  return (
    <>
      {
        <div
          className="w-full min-h-screen flex flex-col justify-center items-center bg-gray-200 px-5 bg-cover bg-no-repeat"
          style={{
            backgroundPosition: "center",
            backgroundImage: `url('${helper.getBackground(getBG())}')`,
          }}
        >
          {user?.length !== 0 ? (
            <>
              {index < doorprize.length && (
                <div className="flex flex-col justify-center items-center py-10 w-full">
                  <div className="flex flex-col justify-center items-center space-y-2.5 w-full">
                    {state === "idle" && (
                      <DoorprizeIdle
                        doorprize={doorprize[index]}
                        play={() => handlePlay()}
                      />
                    )}
                    {state === "play" && (
                      <Doorprize
                        doorprize={doorprize[index]}
                        user={user}
                        stop={(val) => handleWinner(val)}
                      />
                    )}
                    {state === "result" && (
                      <DoorprizeWinner
                        back={() => handleBack()}
                        doorprize={doorprize[index]}
                        winner={winner[index]}
                      />
                    )}
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              <div className="relative flex justify-center items-center h-full  px-5 py-10 bg-white bg-opacity-50 backdrop-blur-sm rounded-md w-full md:w-3/4 z-0">
                <div className="text-center font-bold mb-10">
                  <div className="text-black font-semibold mt-8 text-2xl">
                    {loading && user.length === 0 ? (
                      <span>LOADING..</span>
                    ) : (
                      <span>
                        No user has {props?.setting?.minimum_point_doorpize}{" "}
                        points <br/> or all user has points {props?.setting?.minimum_point_doorpize} is a winner
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}

          {user?.length !== 0 && winner.length > 0 && (
            <div className="relative flex justify-center items-center h-full  px-5 py-2 bg-white bg-opacity-50 backdrop-blur-sm rounded-md w-full md:w-3/4 z-0">
              <div className="text-center font-bold mb-10">
                <div className="text-black font-semibold mt-8 text-2xl mb-5">
                  WINNER
                </div>
                <div className={`grid grid-cols-3 gap-10`}>
                  {doorprize?.map((item, key) => {
                    return (
                      <div className="mb-2 border-b-2 py-2 w-full">
                        <div>{item.name}</div>
                        <div className="text-xl">{winner[key]?.name}</div>
                        <div className="text-base">{winner[key]?.team}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
      }
    </>
  );
};

export default PageDoorprize;

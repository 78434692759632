import helper from "../../utils/helper";
import imgLogo from "../../assets/logo-rbl.png";

const PagePointsChecker = (props) => {
  return (
    <>
      {
        <div
          className="w-full min-h-screen flex flex-col justify-center items-center bg-gray-200 px-5 bg-cover bg-no-repeat"
          style={{
            backgroundPosition: "center",
            backgroundImage: `url('${helper.getBackground(
              props?.setting?.background
            )}')`,
          }}
        >
          <div className="flex flex-col justify-center items-center py-10 w-full">
              
            <div className="flex flex-col justify-center items-center space-y-2.5 w-full">
                
              <div className="relative flex justify-center items-center h-full  px-5 py-5 bg-white bg-opacity-50 backdrop-blur-sm rounded-md w-full  z-0">
             
                <div className="flex flex-col justify-center items-center text-center font-bold py-5">
                <div>
          <img className="w-20 h-20 rounded-md mb-4" src={imgLogo} />
        </div>
                 
                  <div className="text-black font-semibold text-2xl">
                    RBL STAFF RALLY 2024 Points Checker
                  </div>

                  <div className="flex flex-col space-y-3 my-4 items-center justify-center mt-10">
                   
                    <a
                      href="./points-user"
                      className={`border-b-4 border-blue-700 border-b-4 bg-blue-500 w-[200px] rounded-md px-4 py-2 text-white font-bold `}
                    >
                      <div>View Individual<br/>Points Balance</div>
                    </a>

                    <a
                      href="./leaderboard"
                      className={`border-b-4 border-blue-700 border-b-4 bg-blue-500 w-[200px] rounded-md px-4 py-2 text-white font-bold `}
                    >
                      <div>View Overall<br/>Leaderboard</div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default PagePointsChecker;
